<template>
  <div>
    <p>Sign-in in progress</p>
    <sdo-loader label="Please Wait..." />
  </div>
</template>

<script>
import SdoLoader from "@/components/Sdo/SdoLoader";

export default {
  components: { SdoLoader },
  async created() {
      await this.$auth.signInSilentCallback();
  }
};
</script>
