<template>
  <div
    v-if="errors && errors.length"
    class="alert alert-danger mt-2 mb-3"
  >
    <div
      v-if="staticMessage"
      class="mb-1"
    >
      {{ staticMessage }}
    </div>
    <div v-if="errors.length === 1">
      {{ errors[0] }}
    </div>
    <ul v-else>
      <li
        v-for="error in errors"
        :key="error"
      >
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {

  props: {
    errors: {
      type: Array,
      default: function() { return []; }
    },
    staticMessage: {
      type: String,
      default: ""
    }
  }

};
</script>
