import { useToast } from "primevue/usetoast";

export function useToaster() {
  const toast = useToast();

  const showToast = (severityDefaults, options) => {
    const defaultOptions = {
      autoHide: true,
      life: 5000
    };

    options = Object.assign({}, defaultOptions, severityDefaults, options);

    if (!options.autoHide) {
      delete options.life;
    }

    toast.add(options);
  };

  return {
    info: (options) => {
      showToast({ severity: 'info', summary: 'Message' }, options);
    },
    error: (options) => {
      showToast({ severity: 'error', summary: 'Error', autoHide: false }, options);
    },
    success: (options) => {
      showToast({ severity: 'success', summary: 'Success' }, options);
    },
    warning: (options) => {
      showToast({ severity: 'warn', summary: 'Warning' }, options);
    },
  };
}
