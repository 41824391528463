<template>
  <nav
    v-if="showBreadcrumbs && userStore.profile.initialized"
    aria-label="breadcrumb"
  >
    <ol class="breadcrumb">
      <li
        v-for="(item, index) in items"
        :key="item.text"
        class="breadcrumb-item"
        :class="{'active': (index + 1) === items.length}"
      >
        <router-link
          v-if="(index + 1) < items.length"
          :to="item.to"
        >
          {{ item.text }}
        </router-link>
        <!-- Assume the last item is always the active one -->
        <span
          v-else
          aria-current="location"
        >
          {{ item.text }}
        </span>
      </li>
    </ol>
  </nav>
</template>

<script>
import { useOrganizationStore } from "@/stores/organizationStore";
import { useUserStore } from "@/stores/userStore";

export default {
  name: "BreadcrumbsViewer",

  setup() {
    return {
      orgStore: useOrganizationStore(),
      userStore: useUserStore(),
    };
  },

  data() {
    return {
      showBreadcrumbs: true,
      showWebsiteHomeBreadcrumb: true,
      dynamicItems: []
    };
  },

  computed: {
    items() {
      let breadcrumbs = [];

      if (this.showBreadcrumbs && this.showWebsiteHomeBreadcrumb) {
        breadcrumbs.push({ text: 'Home', to: this.userStore.profile.homeRoute });
      }

      return breadcrumbs.concat(this.dynamicItems);
    }
  },

  watch: {
    async '$route'() {
      if (this.userStore.profile?.initialized) {
        await this.updateBreadcrumbsFromRouteMetadata();
      }
    }
  },

  async mounted() {
    if (this.userStore.profile?.initialized) {
      await this.updateBreadcrumbsFromRouteMetadata();
    }

    let userIdToNameMappings = sessionStorage.getItem('memberIdToNameMappings');
    if (!userIdToNameMappings) {
      sessionStorage.setItem('memberIdToNameMappings', JSON.stringify({}));
    }
  },

  methods: {
    async updateBreadcrumbsFromRouteMetadata() {
      this.showBreadcrumbs = false;
      if (!this.$route.meta || !this.$route.meta.breadcrumbs) {
        this.dynamicItems = [];
        return;
      }

      if (typeof this.$route.meta.breadcrumbs === 'function') {
        this.dynamicItems = JSON.parse(JSON.stringify(this.$route.meta.breadcrumbs(this.$route)));
        await this.setBreadcrumbsFromDataAsync();
      }
      else if (Array.isArray(this.$route.meta.breadcrumbs)) {
        this.dynamicItems = JSON.parse(JSON.stringify(this.$route.meta.breadcrumbs));
        await this.setBreadcrumbsFromDataAsync();
      }
      else {
        this.dynamicItems = [];
      }
    },

    async getLearnerNameAsync(id) {
      let response = await this.$api.getOrgLearnerAsync(this.orgStore.organizationContext.key, id);
      let user = response.data;
      return user.firstName + ' ' + user.lastName;
    },

    async getInspectionEventNameAsync(orgKey, orgUserId, inspectionEventId) {
      let response = await this.$api.getOrgLearnerInspectionEventAsync(orgKey, orgUserId, inspectionEventId);
      let inspectionEvent = response.data;
      return inspectionEvent.name;
    },

    async getLearnerInspectionEventNameAsync(inspectionEventId) {
      let response = await this.$api.getLearnerInspectionEventAsync(inspectionEventId);
      let inspectionEvent = response.data;
      return inspectionEvent.name;
    },

    async getAssignmentNameAsync(assignmentId) {
      let response = await this.$api.getAssignmentNameAsync(assignmentId);
      return response.data.name;
    },

    async getExerciseNameAsync(exerciseId, orgKey) {
      let response = await this.$api.getOrgExerciseAsync(orgKey, exerciseId);
      return response.data.name;
    },

    async setBreadcrumbsFromDataAsync() {
      for (let x of this.dynamicItems) {

        if (x.text === 'Learner') {
          let orgUserId = this.$route.params.orgUserId;
          let nameDict = JSON.parse(sessionStorage.getItem('memberIdToNameMappings'));
          if (nameDict[orgUserId]) {
            x.text = nameDict[orgUserId];
          } else {
            x.text = await this.getLearnerNameAsync(orgUserId);
            nameDict[orgUserId] = x.text;
            sessionStorage.setItem('memberIdToNameMappings', JSON.stringify(nameDict));
          }
        }
        else if (x.text === 'Folder') {
          x.text = await this.getInspectionEventNameAsync(this.$route.params.orgKey, this.$route.params.orgUserId, this.$route.params.inspectionEventId);
        }
        else if (x.text === 'Learner Folder') {
          x.text = await this.getLearnerInspectionEventNameAsync(this.$route.params.inspectionEventId);
        }
        else if (x.text === 'Assignment' && this.$route.params.assignmentId) {
          x.text = await this.getAssignmentNameAsync(this.$route.params.assignmentId);
        }
        else if (x.text === 'View Assignment' && this.$route.params.exerciseId && this.$route.params.orgKey) {
          x.text = await this.getExerciseNameAsync(this.$route.params.exerciseId, this.$route.params.orgKey);
        }
        else if (x.text === 'Organization') {
          x.text = this.userStore.profile.isSingleTenantMember ? 'Home' : this.orgStore.organizationContext.name;
        }

        this.showWebsiteHomeBreadcrumb = !this.userStore.profile.isSingleTenantMember;

      }

      this.showBreadcrumbs = true;
    }

  }
};
</script>
