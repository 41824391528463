<template>
  <form
    novalidate
    @submit.prevent="onSubmitAsync"
  >
    <markdown-editor
      id="comment"
      v-model="selectedComment"
      name="comment"
      :easy-mde-options="editorOptions"
    />

    <error-messages :errors="errors" />

    <div>
      <spinner-button
        type="submit"
        variant="primary"
        class="mt-2"
        :busy="busy"
        :busy-text="busyButtonLabel"
        :disabled="selectedComment.trim() === ''"
      >
        {{ buttonLabel }}
      </spinner-button>
    </div>
  </form>
</template>

<script>
import ErrorMessages from "@/components/ErrorMessages";
import MarkdownEditor from "@/components/MarkdownEditor";
import SpinnerButton from "@/components/SpinnerButton";
import { validation } from "@/mixins/validation";

export default {
  name: "CommentForm",

  components: {
    ErrorMessages,
    MarkdownEditor,
    SpinnerButton
  },

  mixins: [ validation ],

  props: {
    busy: {
      type: Boolean,
      default: false
    },
    relatedEntity: {
      type: Object,
      default: () => {
        return null;
      }
    },
    errors: {
      type: [Error, Object, Array],
      default: () => {
        return null;
      }
    },
    buttonLabel: {
      type: String,
      default: 'Comment'
    },
    busyButtonLabel: {
      type: String,
      default: 'Saving...'
    },
    editorOptions: {
      type: Object,
      default: () => {
        return { minHeight: '60px' };
      }
    }
  },

  emits: ['submitted'],

  data() {
    return {
      selectedComment: ''
    };
  },

  watch: {
    errors() {
      this.handleErrors(this.errors);
    }
  },

  mounted() {
    this.originalData = this.selectedComment;
  },

  methods: {

    isDirty() {
      return this.originalData !== this.selectedComment;
    },

    async onSubmitAsync() {
      this.$emit('submitted', {
        relatedEntity: this.relatedEntity,
        comment: this.selectedComment
      });

      this.selectedComment = '';
    }

  }
};
</script>
