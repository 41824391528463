<template>
  <div class="text-center">
    <h1>A network error has occurred</h1>
    <p>Please reload this page and try again.</p>
  </div>
</template>

<script>
export default {
  name: "NetworkError",
};
</script>
