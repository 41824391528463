<template>
  <small class="text-muted"> {{ preText }}{{ author }}{{ onBehalfOfText }} on {{ toLocalDateTime(dateUtc) }}</small>
</template>

<script>
import { toLocalDateTime } from "@/utils/filters";

export default {
  name: "AuditInfo",

  props: {
    author: {
      type: String,
      default: ''
    },
    dateUtc: {
      type: String,
      default: ''
    },
    onBehalfOf: {
      type: String,
      default: ''
    },
    preText: {
      type: String,
      default: ''
    }
  },

  computed: {
    onBehalfOfText() {
      if (!this.onBehalfOf || this.author === this.onBehalfOf) {
        return "";
      }

      return ` on behalf of ${this.onBehalfOf}`;
    }
  },

  methods: {toLocalDateTime}
};
</script>
