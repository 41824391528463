<template>
  <sdo-button
    :id="elementId($.uid, 'sdoDropdownButton')"
    ref="buttonRef"
    aria-haspopup="menu"
    :aria-expanded="computedExpanded"
    @click="clicked"
    @keydown.arrow-down="buttonArrowKeyDown($event)"
  >
    <slot name="button" />
    <overlay-panel
      ref="menuOverlayRef"
      no-body-class
      :no-caret="props.noCaret === true ? true : null"
    >
      <ul
        ref="menuRef"
        class="sdo-dropdown-button-menu"
        role="menu"
        tabindex="-1"
        :aria-labelledby="elementId($.uid, 'sdoDropdownButton')"
        @click="itemClicked"
        @keydown="itemKeyDown($event)"
      >
        <slot />
      </ul>
    </overlay-panel>
  </sdo-button>
</template>

<script setup>
import {computed, nextTick, ref} from "vue";
import { useElementId } from "@/utils/elementId";
import OverlayPanel from "primevue/overlaypanel";
import SdoButton from "@/components/Sdo/SdoButton";

const buttonRef = ref(null);
const menuOverlayRef = ref(null);
const menuRef = ref(null);
const { elementId } = useElementId();

const props = defineProps({
  noCaret: {
    type: Boolean,
    default: false
  },
});

const buttonArrowKeyDown = (e) => {
  toggleOverlay(e);
  focusElementNextTick(menuRef);
};

const setNextItemFocus = (e, currentIndex, items) => {
  let targetIndex;

  if (e.key === 'ArrowUp') {
    targetIndex = currentIndex <= 0 ? 0 : currentIndex - 1;
  } else {
    targetIndex = currentIndex + 1 >= items.length ? items.length - 1 : currentIndex + 1;
  }

  items[targetIndex].focus();
};

const toggleOverlay = (e) => {
  if (menuOverlayRef.value) {
    menuOverlayRef.value.toggle(e);
  }
};

const focusElementNextTick = (el) => {
  if (!el) {
    return;
  }
  nextTick(() => {
    if (el.value) {
      el.value.focus();
    }
  });
};

const itemKeyDown = (e) => {

  if (!['ArrowDown', 'ArrowUp', 'Tab'].includes(e.key) || !e.currentTarget) {
    return;
  }

  const items = e.currentTarget.querySelectorAll('.dropdown-item');
  let currentIndex = Array.from(items).findIndex((element) => {
    return document.activeElement === element;
  });

  if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && items.length > 0) {
    // cycle up or down the items in the menu
    setNextItemFocus(e, currentIndex, items);
  }

  if (e.key === 'Tab' && !e.shiftKey && currentIndex >= items.length - 1) {
    // user tabbing off last item, focus the button and then the tab default will land them on the next control
    toggleOverlay(e);
    focusElementNextTick(buttonRef);
  }

  if (e.key === 'Tab' && e.shiftKey && currentIndex <= 0) {
    // user tabbing backwards off the first item, set the focus to the dropdown button
    // and prevent the natural tab so focus stays there
    toggleOverlay(e);
    focusElementNextTick(buttonRef);
    e.preventDefault();
  }
};

const clicked = (e) => {
  toggleOverlay(e);
  focusElementNextTick(menuRef);
};

const itemClicked = (e) => {
  toggleOverlay(e);
  focusElementNextTick(buttonRef);
};

const computedExpanded = computed(() => {
  return menuOverlayRef?.value?.visible ?? false;
});

</script>
