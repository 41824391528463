import axios from 'axios';
import config from "@/config";
import { EVENT_API_SECURITY_CHANGED, EVENT_API_SERVER_ERROR } from "@/applicationEvents";
import {
  API_ERROR_STATE_NETWORK,
  API_ERROR_STATE_NOTFOUND,
  API_ERROR_STATE_UNAUTHORIZED,
  useApiStore
} from "@/stores/apiStore";

export default {
  install(app) {

    const axiosOptions = {
      baseURL: config.apiUrl,
    };

    let axiosInstance = axios.create(axiosOptions);

    app.config.globalProperties.$axios = axiosInstance;

    axiosInstance.interceptors.response.use((response) => {
      if (response && response.headers && response.headers['x-sdo-api-version']) {
        const apiStore = useApiStore();
        apiStore.apiVersion = response.headers['x-sdo-api-version'];
      }
      return response;
    }, (err) => {
      if (err.response && err.response.headers && err.response.headers['x-sdo-api-version']) {
        const apiStore = useApiStore();
        apiStore.apiVersion = err.response.headers['x-sdo-api-version'];
      }
      return Promise.reject(err);
    });

    // This interceptor will handle notifying the user of any unusual and serious
    // errors related to call the api.
    axiosInstance.interceptors.response.use(undefined, err => {
      // Check the global bypass flag, if the flag is set then any errors will not be handled globally and the caller
      // will have an opportunity to handle it however it would like
      if (err.config && err.config.bypassGlobalExceptionHandling === true) {
        return Promise.reject(err);
      }

      const apiStore = useApiStore();

      if (err.isAxiosError && !err.response) {
        apiStore.setApiError(API_ERROR_STATE_NETWORK);
        return Promise.reject(err);
      }

      if (err.response && err.response.status === 404) {
        apiStore.setApiError(API_ERROR_STATE_NOTFOUND);
        return Promise.reject(err);
      }

      if (err.response && err.response.status === 400 && err.response.data) {
        // Assume BadRequest with data will be handled up the chain by something like validation.js
        return Promise.reject(err);
      }

      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        const bearerToken = err.config.headers.Authorization;
        let token_in_storage = '';

        if (typeof(Storage) !== "undefined") {
          let keys = Object.keys(localStorage);

          keys.forEach(function (key) {
            if (key.startsWith('oidc.user')) {
              const entryJson = localStorage.getItem(key);
              if (entryJson) {
                const entry = JSON.parse(entryJson);
                if (entry) {
                  token_in_storage += `expires at: ${entry.expires_at}. access_token: ${entry.access_token}.`;
                }
              }
            }
          });
        }

        console.info(`401 returned from API call. Attempted to use bearer token: ${bearerToken}. Token in local storage has ${token_in_storage}`, new Date());
        apiStore.setApiError(API_ERROR_STATE_UNAUTHORIZED);
        return Promise.reject(err);
      }

      if (err.response && err.response.data && typeof err.response.data === 'string' && err.response.data.includes('SecurityTokenSignatureKeyNotFoundException')) {
        document.getElementById('app').dispatchEvent(new CustomEvent(EVENT_API_SECURITY_CHANGED));
        return Promise.reject(err);
      }

      let message = 'An error occurred with the API.  ';

      if (err.config &&
        (err.config.method === "post" || err.config.method === "put")) {
        message = 'Sorry, an error occurred while saving changes.';
      }
      else {
        if (err.response && err.response.data) {
          if (err.response.data.title) {
            message = message + err.response.data.title;
          } else if (err.response.status === 500) {
            console.log("API internal server error: " + err.response.data);
            if (err.response.statusText) {
              message = message + `(${err.response.statusText})`;
            }
          } else {
            message = message + `(${err.response.data})`;
          }
        } else {
          message = message + `(${err})`;
        }
      }

      document.getElementById('app').dispatchEvent(new CustomEvent(EVENT_API_SERVER_ERROR, { detail: message }));

      return Promise.reject(err);
    });
  }
};
