
export function toLocalDateTime(value) {
  if (value) {
    let date = new Date(value);
    let options = {
      dateStyle: 'medium',
      timeStyle: 'medium',
    };
    return Intl.DateTimeFormat([], options).format(date);
  }
  return '';
}

export function toLocalDate (value) {
  if (value) {
    let date = new Date(value);
    let options = {
      dateStyle: 'medium'
    };
    return Intl.DateTimeFormat([], options).format(date);
  }
  return '';
}

export function toFileSize(value) {
  if (value) {
    if (value > 1000000) {
      return Math.floor(Math.round(value / 1000000)) + "MB";
    }
    else if (value > 1000) {
      return Math.floor(Math.round(value / 1000)) + "KB";
    }
    else {
      return value + "B";
    }
  }

  return '';
}

export function toYesNo (value) {
  return value ? 'Yes' : 'No';
}
