export const Bootstrap_PT = {

  column: {
    root: "align-middle",
    sort: {
      class: "ml-2",
      style: {
        cursor: 'pointer'
      }
    }
  },

  datatable: {
    root: {
      class: ['pv-data-table-root']
    },
    table: () => ({
      class: [
        "table",
        "table-striped",
        "table-sm",
        "font-size-sm",
      ]
    }),
    thead: {
      class: ['bg-white', 'thead-sticky']
    },
    headerRow: () => ({
      class: [
        "text-nowrap"
      ]
    }),
    loadingOverlay: "loading-container",
    wrapper: {
      class: ['pv-data-table-wrapper']
    }
  },

  sidebar: {
    root: ({ props }) => ({
      class: ["sidebar-container", "bg-light", "shadow", props.position === 'right' ? 'sidebar-right' : 'sidebar-left'],
    }),
    header: {
      class: ["sidebar-header"],
    },
    content: {
      class: ["sidebar-content"],
    },
    mask: {
      class: ["sidebar-mask", "sidebar-mask-enter"],
    },
    closeButton: {
      class: "bg-transparent hover-opacity border-0 d-flex p-1 ml-auto"
    },
    transition: ({ props }) => props.position === 'right' ? {
      enterFromClass: "sidebar-enter-from-right",
      leaveToClass: "sidebar-leave-to-right"
    } : {
      enterFromClass: "sidebar-enter-from-left",
      leaveToClass: "sidebar-leave-to-left"
    }
  },

  tabView: {
    nav: "nav nav-tabs my-3"
  },

  tabPanel: {
    content: "p-1",
    headerAction: (options) => ({
      class: [
        "nav-link",
        {
          "active": options.tabpanel.context.index === options.state.d_activeIndex
        }
      ],
      style: "cursor: pointer;"
    }),
    nav: "my-2",
    root: "nav-item",
  },

  toast: {
    container: (options) => ({
      class: [
        "toast-container px-3 py-2 fs-9 alert",
        {
          "alert-info": options.props.message.severity === "info",
          "alert-success": options.props.message.severity === "success",
          "alert-danger": options.props.message.severity === "error",
          "alert-warning": options.props.message.severity === "warn",
        },
      ],
    }),

    content: {
      class: "d-flex"
    },

    icon: {
      class: "d-inline-block flex-shrink-0 mr-3 mt-1 size-24px"
    },

    summary: {
      class: "font-weight-bold"
    },

    buttonContainer: {
      class: "ml-auto",
    },

    closeButton: {
      class: "bg-transparent hover-opacity border-0 d-flex p-1"
    },

    transition: {
      enterFromClass: "toast-message-enter-from",
      enterActiveClass: "toast-message-enter-active",
      leaveFromClass: "toast-message-leave-from",
      leaveActiveClass: "toast-message-leave-active",
      leaveToClass: "toast-message-leave-to"
    }
  },

  overlaypanel: {
    root: (options) => ({
      class:[
        "popover",
        {
          'no-caret': Object.hasOwn(options.attrs, 'no-caret') && (options.attrs['no-caret'] === true),
          'mt-2': !Object.hasOwn(options.attrs, 'no-caret') || (options.attrs['no-caret'] !== true),
        }
      ]
    }),
    content: (options) => ({
      class: [
        {
          'popover-body': !Object.hasOwn(options.attrs, 'no-body-class')
        }
      ]
    })
  },
};
