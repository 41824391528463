import { defineStore } from "pinia";

const defaultState = {
  orgUserId: null,
  initialized: false,
  uniqueId: '',
  key: '',
  name: '',
  masterLibraryEnabled: false,
  canManageExercises: false,
  canRestoreExercises: false,
  canManageMembers: false,
  canViewExercises: false,
  canViewLearners: false,
  canViewOrgMembers: false,
  canArchiveLearnerFolders: false,
  canModerateLearnerFolders: false,
  canModerateLearnerSubmissionFeedback: false,
  canRecallLearnerSubmissions: false,
  isLearner: false
};

export const useOrganizationStore = defineStore('organizationStore', {
  state: () => {
    return {
      organizationContext: { ...defaultState }
    };
  },

  actions: {

    async getOrganizationContextAsync(orgKey) {
      // Cache the call based on orgKey, otherwise we'll make it over and over again
      if (Object.keys(this.organizationContext).length > 0 && this.organizationContext.key.length && orgKey === this.organizationContext.key) {
        return;
      }

      const response = await this.app.config.globalProperties.$api.getOrganizationContextAsync(orgKey);

      if (response.data) {
        this.organizationContext = { ...response.data, initialized: true };
      } else {
        this.organizationContext = { ...defaultState, initialized: true };
      }
    },

  }
});
