<template>
  <button
    ref="buttonRef"
    class="btn"
    :class="computedClasses"
    :aria-pressed="isToggle ? pressedValue : null"
    :disabled="props.disabled"
    :type="props.type"
    @click="clicked($event)"
    @keydown="onKeyDown($event)"
  >
    <slot />
  </button>
</template>

<script setup>
import {
  computed,
  ref, toRef,
} from "vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: null,
    required: false
  },
  block: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  pill: {
    type: Boolean,
    default: false
  },
  pressed: {
    type: Boolean,
    default: null,
    required: false
  },
  size: {
    type: String,
    default: 'md'
  },
  squared: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'button'
  },
  variant: {
    type: String,
    default: 'secondary'
  },
});

const emit = defineEmits(['click', 'keydown', 'update:pressed']);

const buttonRef = ref(null);
const pressedValue = ref(false);
const isToggle = toRef(() => typeof props.pressed === 'boolean');
pressedValue.value = isToggle.value ? props.pressed : null;

const computedClasses = computed(() => [
  [`btn-${props.size}`],
  {
    [`btn-${props.variant}`]: props.variant !== null,
    'btn-block': !!props.block,
    'rounded-pill': !!props.pill,
    'rounded-0': !!props.squared,
    'disabled': !!props.disabled,
    'active': props.active || props.pressed,
  },
]);

const clicked = e => {
  emit('click', e);
  if (isToggle.value) {
    pressedValue.value = !pressedValue.value;
    emit('update:pressed', pressedValue.value);
  }
};

const onKeyDown = (e) => {
  emit('keydown', e);
};

const focus = () => {
  buttonRef.value.focus();
};

defineExpose({
  focus
});
</script>
