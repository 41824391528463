import {UserManager, WebStorageStateStore} from "oidc-client-ts";
import config from "@/config";
import * as utils from "@/mixins/urlGenerator";
import { EVENT_AUTH_REFRESH_NEEDED } from "@/applicationEvents";

// import {Log} from "oidc-client-ts";
// Log.setLogger(console);
// Log.setLevel(Log.DEBUG);

let baseUrl = `${window.location.protocol}//${window.location.host}`;

const settings = {
  userStore: new WebStorageStateStore({store: window.localStorage}),
  authority: config.oidcDomain,
  client_id: config.oidcClientId,
  redirect_uri: `${baseUrl}/authCallback`,
  silent_redirect_uri: `${baseUrl}/authSilentRenew`,
  post_logout_redirect_uri: `${baseUrl}`,
  response_type: "code",
  scope: "openid offline_access profile email", // offline_access necessary to get a refresh_token
  loadUserInfo: true,
  filterProtocolClaims: true,
  automaticSilentRenew: true,
  silentRequestTimeoutInSeconds: 15, // 15 seconds (default is 10)
  accessTokenExpiringNotificationTimeInSeconds: 120 // 2 minutes
};

if (config.fusionAuthEnabled) {
  settings.metadataUrl = utils.urlGenerator.methods.generateFusionAuthMetadataUrl();
}

const userManager = new UserManager(settings);
const pauseForSimultaneousRefreshCheckInMilliseconds = 2 * 1000; // 2 seconds
const networkErrorRetryTimeInMilliseconds = 10000;

let userAskedToRefresh = false;
let isInNetworkErrorResiliencyRetryProcess = false;
let accessTokenExpiresAt = null;

export default class AuthService {

  constructor(axios) {
    this.$axios = axios;
    this.registerEventHandlers();
  }

  getUser = () => {
    return userManager.getUser();
  }

  login = () => {
    return userManager.signinRedirect({ state: window.location.pathname });
  }

  logout = () => {
    return userManager.signoutRedirect();
  }

  registerEventHandlers = () => {

    userManager.events.addSilentRenewError(async (error) => {
      if (userAskedToRefresh === true) {
        return;
      }

      console.error(`An error has occurred during the silent renew process (${error.message}${error.error ? ' ' + error.error : ''}).`, new Date());

      if (error.message && this.isNetworkError(error) && !isInNetworkErrorResiliencyRetryProcess) {

        // A network error has occurred.
        // Don't fail right away but wait and try again in case the network issue is temporary.

        isInNetworkErrorResiliencyRetryProcess = true;
        console.error(`As this may be a network error, waiting ${networkErrorRetryTimeInMilliseconds/1000} seconds to try again.`, new Date());

        setTimeout(async () => {
          await userManager.getUser(); // Test the network and attempt to refresh the tokens
        }, networkErrorRetryTimeInMilliseconds);

        return;
      }

      if (error.message && error.message.toLowerCase().includes('refresh_token is invalid')) {

        // The refresh token is invalid (most likely expired).
        // Although the refresh has failed in this browser tab, it may have succeeded in another tab.
        // Wait a few seconds in case another tab refreshed the token and then inspect the access token to find out.
        console.info(`Pausing for ${pauseForSimultaneousRefreshCheckInMilliseconds/1000} seconds before requesting updated token from userManager...`, new Date());

        setTimeout(async () => {
          console.info(`pause for refresh token complete. Getting user from userManager.`, new Date());
          let user = await userManager.getUser();

          // If after waiting a while longer and the access token expiry date wasn't renewed by another
          // tab, ask the user to refresh the authentication.

          if (!user) {
            console.info(`userManager.getUser responded with null user!`, new Date());
          }

          if (!user || user.expires_at === accessTokenExpiresAt) {
            console.error(`An error has occurred during the silent renew process after waiting ${pauseForSimultaneousRefreshCheckInMilliseconds/1000} seconds and trying again (${error.message} ${error.error}).`, new Date());
            this.showAuthenticationRefreshModal("refresh token invalid");
          } else {
            console.info(`The access token got refreshed (expiration ${user.expires_at}).`, new Date());
            this.setDefaultApiToken(user);
          }
        }, pauseForSimultaneousRefreshCheckInMilliseconds);

        return;
      }

      // An unexpected error has occurred. Nothing else can be done so we let the user know immediately.
      console.error(`The refresh token is invalid. Authentication will need to be refreshed.`, new Date());
      let friendlyErrorMessage = this.isNetworkError(error) ? 'network error' : error.message;
      this.showAuthenticationRefreshModal(friendlyErrorMessage);
    });

    userManager.events.addUserLoaded((user) => {
      console.info(`userManager addUserLoaded event handler is setting default Api Token`, new Date());
      this.setDefaultApiToken(user);
    });
  }

  signInRedirectCallback = () => {
    userManager.signinRedirectCallback()
      .then((user) => {
        console.info(`userManager signinRedirectCallback handler is setting default Api Token`, new Date());
        this.setDefaultApiToken(user);

        if (user.state) {
          window.location.href = user.state;
        }
        else {
          window.location.href = '../';
        }
      }).catch(function (err) {
        console.log('Error received during signinRedirectCallback.', err);
        window.location.href = '/authenticationError';
      });
  }

  static signInSilentCallback = () => {
    userManager.signinSilentCallback()
      .then((user) => {
        console.info(`userManager signinSilentCallback handler is setting default Api Token`, new Date());
        this.setDefaultApiToken(user);
      }).catch(function (err) {
        throw err;
    });
  }

  setDefaultApiToken = (user) => {
    console.info(`setting default api token with expires_at: ${user.expires_at}`, new Date());
    accessTokenExpiresAt = user.expires_at;
    isInNetworkErrorResiliencyRetryProcess = false;
    this.$axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
  }

  showAuthenticationRefreshModal = (errorDescription) => {
    userAskedToRefresh = true;
    userManager.removeUser(); // Ensure any tokens for this user are forgotten
    document.getElementById('app').dispatchEvent(new CustomEvent(EVENT_AUTH_REFRESH_NEEDED, { detail: errorDescription }));
  }

  isNetworkError(error) {
    let errorMessage = error?.message?.toLowerCase();
    return errorMessage.includes('failed to fetch') // Chromium
      || errorMessage.includes('load failed') // Safari
      || errorMessage.includes('networkerror'); // FireFox
  }
}
