<template>
  <div
    v-if="!!slots['default'] && isVisible"
    :class="['alert', variantClass]"
    role="alert"
  >
    <slot />
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  ref,
  useSlots
} from "vue";

const slots = useSlots();

const props = defineProps({
  variant: {
    type: String,
    default: 'info'
  },
  hideAfterSeconds: {
    type: Number,
    default: 0
  }
});

const isVisible = ref(true);
const variantClass = computed(() => {
  return `alert-${props.variant}`;
});

onMounted(() => {
  if (props.hideAfterSeconds > 0) {
    setTimeout(() => isVisible.value = false, props.hideAfterSeconds * 1000);
  }
});
</script>
