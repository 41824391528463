<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- The text being rendered is sanitized to not allow cross-site scripting -->
  <div
    class="last-child-no-mb markdown"
    v-html="markdown"
  />
  <!--eslint-enable-->
</template>

<script>
import { marked } from 'marked';
import createDomPurify from 'dompurify';

const DOMPurify = createDomPurify(window);

export default {
  name: "MarkdownViewer",

  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },

  computed: {
    markdown() {
      marked.setOptions({
        renderer: new marked.Renderer(),
        gfm: true,
        tables: true,
        breaks: true,
        pedantic: false,
        smartLists: true,
        smartypants: false
      });
      return this.modelValue ? DOMPurify.sanitize(marked(this.modelValue)) : '';
    }
  }
};
</script>
