import '@babel/polyfill';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import ApiPlugin from '@/plugins/api';
import AuthPlugin from '@/plugins/auth';
import AxiosPlugin from '@/plugins/axios';
import ElementIdPlugin from '@/plugins/element-id';
import IconsPlugin from '@/plugins/icons';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import { Bootstrap_PT } from "@/assets/styles/primevue-passthrough-bootstrap";

import '@/assets/styles/_imports.scss';

const app = createApp(App)
  .use(AxiosPlugin)
  .use(PrimeVue, {
    unstyled: true,
    pt: Bootstrap_PT
  })
  .use(ToastService)
  .use(ApiPlugin)
  .use(AuthPlugin)
  .use(ElementIdPlugin)
  .use(IconsPlugin)
  .use(router)
  .use(i18n);

const pinia = createPinia()
  .use(({ app }) => { return { app }; });
app.use(pinia);

// https://router.vuejs.org/guide/migration/#Removal-of-router-app
router.app = app;

app.mount('#app');
