<template>
  <div
    v-cloak
  >
    <sdo-loader
      v-if="busy"
      label="Loading Application..."
    />
    <div v-if="!busy">
      <sdo-alert
        v-if="showEnvironmentAlert"
        class="environmentAlert mb-0"
        variant="warning"
      >
        Environment: {{ getEnvironment() }}
      </sdo-alert>
      <header>
        <nav
          class="navbar navbar-light navbar-expand border-bottom mb-4"
        >
          <div class="container">
            <div class="navbar-brand ml-lg-3">
              <router-link
                v-if="userStore.profile.initialized"
                :to="userStore.profile.homeRoute"
              >
                <img
                  src="./assets/scrumorg-logo.png"
                  class="d-inline-block align-top"
                  alt="Scrum.org Logo"
                  height="32"
                  width="32"
                >
                Scrum.org Portfolio
              </router-link>
            </div>

            <ul class="navbar-nav flex-row mr-lg-3">
              <li class="nav-item dropdown">
                <sdo-dropdown-button
                  v-if="userStore.profile && (userStore.profile.canManageOrgs || userStore.profile.canManageUsers || userStore.profile.canManageMasterLibrary)"
                  right
                  no-caret
                  variant="none"
                  class="nav-link"
                >
                  <template #button>
                    <i-bi-gear-wide class="sdo-icon-lg" />
                  </template>

                  <sdo-dropdown-item
                    v-if="userStore.profile.canManageMasterLibrary"
                    :to="{ name: 'AdminMasterLibrary' }"
                  >
                    Master Assignment Library
                  </sdo-dropdown-item>
                  <sdo-dropdown-item
                    v-if="userStore.profile.canManageOrgs"
                    :to="{ name: 'AdminManageOrgs' }"
                  >
                    Organizations
                  </sdo-dropdown-item>
                  <sdo-dropdown-item
                    v-if="userStore.profile.canManageUsers"
                    :to="{ name: 'AdminUsers' }"
                  >
                    Users
                  </sdo-dropdown-item>
                </sdo-dropdown-button>
              </li>
              <li class="nav-item dropdown">
                <sdo-dropdown-button
                  right
                  no-caret
                  variant="none"
                  class="nav-link ml-2"
                >
                  <template #button>
                    <i-bi-person-circle class="sdo-icon-lg" />
                  </template>

                  <div
                    v-if="isLoggedIn"
                    class="p2 text-center"
                  >
                    <span class="text-muted font-size-sm">Logged in as</span><br>
                    <strong>{{ currentUserName }}</strong>
                  </div>

                  <hr v-if="isLoggedIn">
                  <template v-if="showOrgList">
                    <sdo-dropdown-item
                      v-for="membership in userStore.profile.organizationMemberships"
                      :key="membership.organization.id"
                      :to="{ name: 'OrganizationLanding', params: { orgKey: membership.organization.key } }"
                    >
                      {{ membership.organization.name }}
                    </sdo-dropdown-item>
                    <hr>
                  </template>
                  <sdo-dropdown-item
                    @click="copyVersionNumbersToClipboard"
                  >
                    Version
                  </sdo-dropdown-item>
                  <sdo-dropdown-item
                    v-if="!isLoggedIn"
                    @click="login"
                  >
                    Login
                  </sdo-dropdown-item>
                  <sdo-dropdown-item
                    v-if="isLoggedIn"
                    @click="logout"
                  >
                    Logout
                  </sdo-dropdown-item>
                </sdo-dropdown-button>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div class="mb-5">
        <learner-messages
          v-if="messagingAvailable"
          :context="learnerMessagingContext"
        />
        <router-view />
      </div>

      <footer class="border-top footer text-muted">
        <div class="container d-flex">
          <span>&copy; {{ currentYear }} - Scrum.org</span>
          <div class="ml-4">
            <a
              href="https://www.scrum.org/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>
    </div>

    <toast />

    <toast group="apiSecurityChanged">
      <template #message="">
        <div>
          We've made changes that require a restart. Note that any unsaved changes will be lost!
          <p class="text-center m-2">
            <a
              href="/"
              class="btn btn-secondary btn-sm"
            >
              Restart Application
            </a>
          </p>
        </div>
      </template>
    </toast>

    <authentication-refresh-modal ref="authRefreshModal" />
  </div>
</template>

<script>
import AuthenticationRefreshModal from "@/components/AuthenticationRefreshModal";
import LearnerMessages from "@/components/LearnerMessages";
import config from "@/config";
import { MESSAGING_CONTEXT_ORG, MESSAGING_CONTEXT_LEARNER } from '@/components/LearnerMessages';
import {
  EVENT_API_SECURITY_CHANGED,
  EVENT_API_SERVER_ERROR,
  EVENT_AUTH_REFRESH_NEEDED,
} from "@/applicationEvents";
import { useApiStore } from "@/stores/apiStore";
import { useOrganizationStore } from "@/stores/organizationStore";
import { useUserStore } from "@/stores/userStore";
import { useToaster } from "@/utils/useToaster";
import Toast from "primevue/toast";
import SdoAlert from "@/components/Sdo/SdoAlert";
import SdoLoader from "@/components/Sdo/SdoLoader";
import SdoDropdownItem from "@/components/Sdo/SdoDropdownItem";
import SdoDropdownButton from "@/components/Sdo/SdoDropdownButton";

export default {
  name: 'App',

  components: {
    SdoAlert,
    SdoDropdownButton,
    SdoDropdownItem,
    SdoLoader,
    AuthenticationRefreshModal,
    LearnerMessages,
    Toast,
  },

  setup() {
    return {
      apiStore: useApiStore(),
      orgStore: useOrganizationStore(),
      userStore: useUserStore(),
      toast: useToaster(),
    };
  },

  data() {
    return {
      isLoggedIn: false,
      busy: true,
      currentUserName: '',
      learnerMessagingContext: null,
    };
  },

  computed: {

    versionText() {
      let versions = [];

      if (this.apiStore.clientVersion) {
        versions.push(`Client version: ${this.apiStore.clientVersion.trim()}`);
      }

      if (this.apiStore.apiVersion) {
        versions.push(`API version: ${this.apiStore.apiVersion.trim()}`);
      }

      return versions.join(', ');
    },

    currentYear() {
      return new Date().getFullYear();
    },

    showEnvironmentAlert() {
      let environment = this.getEnvironment();
      return environment === 'local' || environment === 'development';
    },

    messagingAvailable() {
      return this.learnerMessagingContext &&
        (this.learnerMessagingContext === MESSAGING_CONTEXT_ORG ||
         (this.learnerMessagingContext === MESSAGING_CONTEXT_LEARNER && this.orgStore.organizationContext.isLearner));
    },

    showOrgList() {
      return this.userStore.profile?.initialized
        && !this.userStore.profile.isSingleTenantMember
        && Array.isArray(this.userStore.profile.organizationMemberships)
        && this.userStore.profile.organizationMemberships.length > 0;
    }
  },

  watch: {
    async $route() {
      this.learnerMessagingContext = this.$route.meta.learnerMessagingContext;
    }
  },

  created() {
    document.getElementById('app').addEventListener(EVENT_API_SECURITY_CHANGED, this.onApiSecurityChanged);
    document.getElementById('app').addEventListener(EVENT_API_SERVER_ERROR, this.onApiServerError);
    document.getElementById('app').addEventListener(EVENT_AUTH_REFRESH_NEEDED, this.onAuthRefreshNeeded);
  },

  async mounted() {
    // Reset any items cached in session storage
    sessionStorage.setItem('memberIdToNameMappings', JSON.stringify({}));

    this.learnerMessagingContext = this.$route.meta.learnerMessagingContext;

    this.$auth.getUser().then(async (user) => {

      this.isLoggedIn = (user !== null && !user.expired);

      if (user !== null) {
        this.currentUserName = user.profile.name ?? `${user.profile.given_name} ${user.profile.family_name}`;
      }

      // For invitations, we can't get the profile as they aren't fully set up in the system.
      // For unsubscribe, we don't want to force the user to login in order to unsubscribe.
      // For shared links, they allow anonymous access meaning the user isn't loaded yet which prevents the bearer token being sent in the API calls resulting in access denied, even for authenticated users.
      if (this.isLoggedIn &&
        !window.location.pathname.startsWith('/invitation') &&
        !window.location.pathname.includes('/unsubscribe') &&
        !window.location.pathname.startsWith('/shared/')
      ) {
        console.info(`App.vue is setting default Api Token`, new Date());
        this.$auth.setDefaultApiToken(user);
        await this.userStore.getProfileAsync();
      } else {
        this.userStore.resetProfile();
      }

    }).finally(() => {
      this.busy = false;
    });

    this.getClientVersionAsync();
  },

  methods: {

    login() {
      this.$auth.login();
    },

    logout() {
      this.$auth.logout();
    },

    getEnvironment() {
      let env = config.envName ?? '';
      return env.toLowerCase();
    },

    async getClientVersionAsync() {
      let response = await fetch('/version.txt');
      if (response.ok) {
        let version = await response.text();
        if (version) {
          // Generally the version file will contain a git hash so we should only need at most 7 characters
          this.apiStore.clientVersion = version.substring(0,7);
        }
      }
    },

    copyVersionNumbersToClipboard() {
      navigator.clipboard.writeText(this.versionText);
      this.toast.success({
        detail: 'Version numbers copied to clipboard'
      });
    },

    onApiSecurityChanged() {
      this.toast.error({
        group: 'apiSecurityChanged'
      });
    },

    onApiServerError(e) {
      this.toast.error({
        detail: e.detail
      });
    },

    onAuthRefreshNeeded(e) {
      this.$refs.authRefreshModal.show(e.detail);
    },

  }
};
</script>

<style scoped>

.environmentAlert {
  position: sticky;
  top: 0;
  padding: 5px;
  z-index: 999;
  text-align: center;
  font-size: 0.9rem;
}

</style>

