/**
 * Borrowed from https://github.com/berniegp/vue-unique-id
 *
 * Decided to copy the code so we don't need yet another dependency. Also, we don't need all the functionality or
 * configuration either.
 */

const methods = {
  /**
   * Generate a component-scoped unique HTML identifier.
   *
   * Example: $id('my-id') => 'uid-42-my-id'
   *
   * @param uidProperty
   */
  $idFactory(uidProperty) {
    return function $id(id = '') {
      return `${this[uidProperty]}-${id}`;
    };
  },

  /**
   * Generate a component-scoped unique HTML identifier reference. Prepends '#' to the id generated
   * by the call $id(id).
   *
   * Example: $idRef('my-id') => '#uid-42-my-id'
   *
   * @param {string} id id to scope
   */
  $idRef(id) {
    return `#${this.$id(id)}`;
  },
};

export default function install(app) {
  const uidProperty = 'uid';
  const uidPrefix = 'uid-';

  // Assign a unique id to each component
  let uidCounter = 0;
  app.mixin({
    beforeCreate() {
      uidCounter += 1;
      const uid = uidPrefix + uidCounter;
      Object.defineProperties(this, {
        [uidProperty]: { get() { return uid; } },
      });
    },
  });

  app.config.globalProperties.$id = methods.$idFactory(uidProperty);
  app.config.globalProperties.$idRef = methods.$idRef;
}
