<template>
  <div class="text-center">
    <h1>Authentication Error</h1>
    <p>An error occurred during authentication.</p>
    <sdo-button
      class="mt-4"
      @click="restartApplication"
    >
      Restart Application
    </sdo-button>
  </div>
</template>

<script>
import router from "@/router";
import SdoButton from "@/components/Sdo/SdoButton";

export default {
  name: "AuthenticationError",
  components: { SdoButton },

  methods: {
    restartApplication() {
      router.push("/");
    }
  }

};
</script>
