<template>
  <sdo-modal
    id="authRefreshModal"
    ref="authRefreshModal"
    title="Authentication Renewal"
    :show-footer="false"
    centered
  >
    <p>
      Either your authentication has expired or there was a problem automatically renewing it.
    </p>
    <p v-if="error">
      Error: <em>{{ error }}</em>
    </p>
    <p>
      If you have unsaved changes you'd like to keep, please do the following to ensure your work is not lost:
    </p>
    <ol>
      <li>Close this modal window</li>
      <li>Copy the data somewhere, like a text editor</li>
      <li>Refresh your browser</li>
      <li>Manually re-apply your changes</li>
    </ol>
    <p class="mb-4">
      If there are no unsaved changes, please click <em>Refresh Now</em> to re-authenticate.
    </p>
    <div class="text-center">
      <sdo-button
        variant="primary"
        @click="refreshAuthentication"
      >
        Refresh Now
      </sdo-button>
    </div>
  </sdo-modal>
</template>

<script>
import SdoButton from "@/components/Sdo/SdoButton";
import SdoModal from "@/components/Sdo/SdoModal";
import router from "@/router";

export default {
  components: {
    SdoButton,
    SdoModal,
  },

  data() {
    return {
      error: ''
    };
  },

  methods: {
    show(error) {
      this.error = error;
      this.$refs.authRefreshModal.show();
    },

    refreshAuthentication() {
      router.go(0);
    },
  },
};
</script>
