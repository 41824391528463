<template>
  <div :class="{ 'container': hasContainer }">
    <sdo-breadcrumb v-if="!apiStore.apiErrorState && hasBreadcrumbs && userStore.profile.initialized" />
    <not-found v-if="apiStore.apiErrorState === API_ERROR_STATE_NOTFOUND" />
    <unauthorized v-else-if="apiStore.apiErrorState === API_ERROR_STATE_UNAUTHORIZED" />
    <network-error v-else-if="apiStore.apiErrorState === API_ERROR_STATE_NETWORK" />
    <router-view v-show="apiStore.apiErrorState === API_ERROR_STATE_NONE" />
  </div>
</template>

<script>
import {
  API_ERROR_STATE_NONE,
  API_ERROR_STATE_UNAUTHORIZED,
  API_ERROR_STATE_NOTFOUND,
  API_ERROR_STATE_NETWORK,
  useApiStore
} from "@/stores/apiStore";
import SdoBreadcrumb from "@/components/Sdo/SdoBreadcrumb";
import NetworkError from "@/components/errors/NetworkError";
import NotFound from "@/components/errors/NotFound";
import Unauthorized from "@/components/errors/Unauthorized";
import { useUserStore } from "@/stores/userStore";

export default {
  name: "DefaultLayout",

  components: {
    SdoBreadcrumb,
    NotFound,
    NetworkError,
    Unauthorized
  },

  props: {

    hasBreadcrumbs: {
      type: Boolean,
      default: true
    },

    hasContainer: {
      type: Boolean,
      default: true
    }

  },

  setup() {
    return {
      apiStore: useApiStore(),
      userStore: useUserStore(),
    };
  },

  data() {
    return {
      API_ERROR_STATE_UNAUTHORIZED,
      API_ERROR_STATE_NOTFOUND,
      API_ERROR_STATE_NONE,
      API_ERROR_STATE_NETWORK
    };
  },

};
</script>
