<template>
  <div class="text-center">
    <h1>Page Not Found</h1>
    <p>The page you requested could not be found.</p>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
