<template>
  <li
    role="presentation"
  >
    <sdo-link
      v-if="tag === 'a'"
      class="dropdown-item"
      v-bind="$attrs"
      role="menuitem"
      :propagate="true"
      @click="clicked"
    >
      <slot />
    </sdo-link>
    <sdo-button
      v-else
      class="dropdown-item"
      variant="link"
      v-bind="$attrs"
      role="menuitem"
      @click="clicked"
    >
      <slot />
    </sdo-button>
  </li>
</template>

<script>
export default {
  inheritAttrs: false
};
</script>

<script setup>
import { computed, useAttrs } from "vue";
import SdoButton from "@/components/Sdo/SdoButton";
import SdoLink from "@/components/Sdo/SdoLink";

const emit = defineEmits(['click']);
const attrs = useAttrs();

const tag = computed(() => {
  if (attrs.to || attrs.href) {
    return 'a';
  }
  return 'button';
});

const clicked = e => {
  e.preventDefault();

  emit('click', e);
};

</script>
