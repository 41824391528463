import AuthCallback from '@/views/AuthCallback.vue';
import AuthSilentRenew from '@/views/AuthSilentRenew.vue';
import NotFound from "@/components/errors/NotFound";
import DefaultLayout from "@/views/DefaultLayout";
import AuthenticationError from '@/views/AuthenticationError';
import { MESSAGING_CONTEXT_ORG, MESSAGING_CONTEXT_LEARNER } from '@/components/LearnerMessages';

export default [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "learner" */ '../views/learner/LearnerHomePage'),
        meta: {
          breadcrumbs: []
        }
      },
      {
        path: 'authCallback',
        name: 'AuthCallback',
        component: AuthCallback,
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: 'authSilentRenew',
        name: 'AuthSilentRenew',
        component: AuthSilentRenew
      },
      {
        path: 'authenticationError',
        name: 'AuthenticationError',
        component: AuthenticationError,
        meta: {
          allowAnonymous: true
        }
      },
      {
        // Although unsubscribe is unsubscribing at an org leve, this path isn't under orgs
        // because we don't want the OrganizationLayout for anonymous users
        path: 'unsubscribe',
        name: 'Unsubscribe',
        component: () => import(/* webpackChunkName: "learner" */ '../views/NotificationUnsubscribe'),
        meta: {
          allowAnonymous: true
        }
      },
      {
        path: 'invitation/:invitationCode',
        name: 'AcceptInvitation',
        props: true,
        component: () => import(/* webpackChunkName: "invitation" */ '../views/AcceptInvitationPage'),
        meta: {
          breadcrumbs: [],
          allowAnonymous: true
        }
      },
      {
        path: 'admin/users',
        name: 'AdminUsers',
        component: () => import(/* webpackChunkName: "adminUsers" */ '../views/admin/AdminUsersPage'),
        meta: {
          breadcrumbs: [
            { text: 'Users' }
          ]
        }
      },
      {
        path: 'admin/users/new',
        name: 'AdminNewUser',
        component: () => import(/* webpackChunkName: "adminUsers" */ '../views/admin/AdminNewUserPage'),
        meta: {
          breadcrumbs: [
            { text: 'Users', to: { name: 'AdminUsers' } },
            { text: 'New User' }
          ]
        }
      },
      {
        path: 'admin/users/:userId/edit',
        name: 'AdminEditUser',
        props: true,
        component: () => import(/* webpackChunkName: "adminUsers" */ '../views/admin/AdminEditUserPage'),
        meta: {
          breadcrumbs: [
            { text: 'Users', to: { name: 'AdminUsers' } },
            { text: 'Edit User' }
          ]
        }
      },
      {
        path: 'admin/orgs',
        name: 'AdminManageOrgs',
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminManageOrgsPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organizations' }
          ]
        }
      },
      {
        path: 'admin/orgs/new',
        name: 'AdminNewOrg',
        props: true,
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminNewOrganizationPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organizations', to: { name: 'AdminManageOrgs' } },
            { text: 'New Organization' }
          ]
        }
      },
      {
        path: 'admin/orgs/:orgId/edit',
        name: 'AdminEditOrg',
        props: true,
        component: () => import(/* webpackChunkName: "admin" */ '../views/admin/AdminEditOrganizationPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organizations', to: { name: 'AdminManageOrgs' } },
            { text: 'Edit Organization' }
          ]
        }
      },
      {
        path: 'admin/master-library',
        name: 'AdminMasterLibrary',
        component: () => import(/* webpackChunkName: "masterLib" */ '../views/admin/AdminMasterLibraryPage'),
        meta: {
          breadcrumbs: [
            { text: 'Master Assignment Library' },
          ]
        }
      },
      {
        path: 'admin/master-library/new',
        name: 'AdminMasterLibraryExerciseNew',
        props: () => ({
          action: 'new'
        }),
        component: () => import(/* webpackChunkName: "masterLib" */ '../views/admin/AdminMasterLibraryExerciseEditPage'),
        meta: {
          breadcrumbs: [
            { text: 'Master Assignment Library', to: { name: 'AdminMasterLibrary' } },
            { text: 'New Assignment' }
          ]
        }
      },
      {
        path: 'admin/master-library/:exerciseId/edit',
        name: 'AdminMasterLibraryExerciseEdit',
        props: route => ({
          action: 'edit',
          exerciseId: route.params.exerciseId,
        }),
        component: () => import(/* webpackChunkName: "masterLib" */ '../views/admin/AdminMasterLibraryExerciseEditPage'),
        meta: {
          breadcrumbs: [
            { text: 'Master Assignment Library', to: { name: 'AdminMasterLibrary' } },
            { text: 'Edit Assignment' }
          ]
        }
      },
      {
        path: 'admin/master-library/:exerciseId/copy',
        name: 'AdminMasterLibraryExerciseCopy',
        props: route => ({
          action: 'copy',
          exerciseId: route.params.exerciseId,
        }),
        component: () => import(/* webpackChunkName: "masterLib" */ '../views/admin/AdminMasterLibraryExerciseEditPage'),
        meta: {
          breadcrumbs: [
            { text: 'Master Assignment Library', to: { name: 'AdminMasterLibrary' } },
            { text: 'Copy Assignment' }
          ]
        }
      },
    ]
  },
  {
    path: '/shared',
    component: DefaultLayout,
    props: () => ({
      hasBreadcrumbs: false
    }),
    children: [
      {
        name: 'SharedInspectionEvent',
        path: 'f/:sharingKey',
        props: true,
        component: () => import(/* webpackChunkName: "learner" */ '../views/learner/SharedInspectionEventPage'),
        meta: {
          allowAnonymous: true
        }
      },
      {
        name: 'SharedInspectionEventAssignment',
        path: 'f/:sharingKey/:assignmentId',
        props: true,
        component: () => import(/* webpackChunkName: "learner" */ '../views/learner/SharedAssignmentPage'),
        meta: {
          allowAnonymous: true
        }
      }

    ]
  },
  {
    path: '/orgs/:orgKey',
    props: true,
    component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationLayout'),
    children: [
      {
        path: '',
        name: 'OrganizationLanding',
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationLandingPage'),
        meta: {
          learnerMessagingContext: MESSAGING_CONTEXT_LEARNER,
          breadcrumbs: [
            { text: 'Organization' }
          ]
        }
      },
      {
        path: 'submissions',
        name: 'OrganizationSubmissions',
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationSubmissionsPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' } },
            { text: 'Submissions' }
          ]
        }
      },
      {
        path: 'my',
        component: DefaultLayout,
        props: () => ({
          hasBreadcrumbs: false,
          hasContainer: false
        }),
        children: [
          {
            path: 'inspection-event/:inspectionEventId',
            name: 'LearnerInspectionEvent',
            props: true,
            component: () => import(/* webpackChunkName: "learner" */ '../views/learner/LearnerInspectionEventPage'),
            meta: {
              learnerMessagingContext: MESSAGING_CONTEXT_LEARNER,
              breadcrumbs: [
                { text: 'Organization', to: { name: 'OrganizationLanding' } },
                { text: 'Learner Folder' }
              ]
            }
          },
          {
            path: 'inspection-event/:inspectionEventId/assignments/:assignmentId',
            name: 'LearnerAssignment',
            props: true,
            component: () => import(/* webpackChunkName: "learner" */ '../views/learner/LearnerAssignmentPage'),
            meta: {
              learnerMessagingContext: MESSAGING_CONTEXT_LEARNER,
              breadcrumbs(route) {
                return [
                  { text: 'Organization', to: { name: 'OrganizationLanding' } },
                  { text: 'Learner Folder', to: { name: 'LearnerInspectionEvent', params: { inspectionEventId: route.params.inspectionEventId } } },
                  { text: 'Assignment' }
                ];
              }
            }
          },
        ]
      },
      {
        path: 'learners',
        component: DefaultLayout,
        props: () => ({
          hasBreadcrumbs: false,
          hasContainer: false
        }),
        children: [
          {
            path: '',
            name: 'OrganizationLearners',
            component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationLearnersPage'),
            meta: {
              breadcrumbs: [
                { text: 'Organization', to: { name: 'OrganizationLanding' } },
                { text: 'Learners' }
              ]
            },
          },
          {
            path: ':orgUserId',
            name: 'OrganizationViewLearner',
            props: true,
            component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationViewLearnerPage'),
            meta: {
              activeMenuItemRouteName: 'OrganizationLearners',
              learnerMessagingContext: MESSAGING_CONTEXT_ORG,
              breadcrumbs: [
                { text: 'Organization', to: { name: 'OrganizationLanding' } },
                { text: 'Learners', to: { name: 'OrganizationLearners' } },
                { text: 'Learner' }
              ]
            }
          },
          {
            path: ':orgUserId/inspection-events/new',
            name: 'OrganizationNewInspectionEvent',
            props: true,
            component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationEditInspectionEventPage'),
            meta: {
              activeMenuItemRouteName: 'OrganizationLearners',
              learnerMessagingContext: MESSAGING_CONTEXT_ORG,
              breadcrumbs: [
                { text: 'Organization', to: { name: 'OrganizationLanding' } },
                { text: 'Learners', to: { name: 'OrganizationLearners' } },
                { text: 'Learner', to: { name: 'OrganizationViewLearner' } },
                { text: 'New Folder' }
              ]
            }
          },
          {
            path: ':orgUserId/inspection-events/:inspectionEventId',
            name: 'OrganizationViewInspectionEvent',
            props: true,
            component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationViewInspectionEventPage'),
            meta: {
              learnerMessagingContext: MESSAGING_CONTEXT_ORG,
              breadcrumbs: [
                { text: 'Organization', to: { name: 'OrganizationLanding' } },
                { text: 'Learners', to: { name: 'OrganizationLearners' } },
                { text: 'Learner', to: { name: 'OrganizationViewLearner' } },
                { text: 'Folder' }
              ]
            }
          },
          {
            path: ':orgUserId/inspection-events/:inspectionEventId/edit',
            name: 'OrganizationEditInspectionEvent',
            props: true,
            component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationEditInspectionEventPage'),
            meta: {
              learnerMessagingContext: MESSAGING_CONTEXT_ORG,
              breadcrumbs: [
                { text: 'Organization', to: { name: 'OrganizationLanding' } },
                { text: 'Learners', to: { name: 'OrganizationLearners' } },
                { text: 'Learner', to: { name: 'OrganizationViewLearner' } },
                { text: 'Edit Folder' }
              ]
            }
          },
          {
            path: ':orgUserId/inspection-events/:inspectionEventId/assignments/:assignmentId',
            name: 'OrganizationViewAssignment',
            props: true,
            component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationViewAssignmentPage'),
            meta: {
              learnerMessagingContext: MESSAGING_CONTEXT_ORG,
              breadcrumbs: [
                { text: 'Organization', to: { name: 'OrganizationLanding' }},
                { text: 'Learners', to: { name: 'OrganizationLearners' } },
                { text: 'Learner', to: { name: 'OrganizationViewLearner' } },
                { text: 'Folder', to: { name: 'OrganizationViewInspectionEvent'} },
                { text: 'Assignment' }
              ]
            }
          }
        ]
      },
      {
        path: 'learners/import',
        name: 'OrganizationLearnersImport',
        props: true,
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationLearnersImportPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' }},
            { text: 'Members', to: { name: 'OrganizationMembers' } },
            { text: 'Learner Import' }
          ]
        }
      },
      {
        path: 'members',
        name: 'OrganizationMembers',
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationMembersPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' }},
            { text: 'Members' }
          ]
        }
      },
      {
        path: 'members/:orgUserId/edit',
        name: 'OrganizationEditMember',
        props: true,
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationEditMemberPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' } },
            { text: 'Members', to: { name: 'OrganizationMembers' } },
            { text: 'Edit Member' }
          ]
        }
      },
      {
        path: 'members/invite',
        name: 'OrganizationInviteMember',
        props: true,
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationMemberInvitePage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' } },
            { text: 'Members', to: { name: 'OrganizationMembers' }},
            { text: 'Invite Member' }
          ]
        }
      },
      {
        path: 'members/roles',
        name: 'OrganizationRoles',
        props: true,
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationRoles'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' } },
            { text: 'Members', to: { name: 'OrganizationMembers' } },
            { text: 'Roles' }
          ]
        }
      },
      {
        path: 'members/roles/new',
        name: 'OrganizationRoleNew',
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationRoleEditPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' } },
            { text: 'Members', to: { name: 'OrganizationMembers' } },
            { text: 'Roles', to: { name: 'OrganizationRoles' } },
            { text: 'New Role' }
          ]
        }
      },
      {
        path: 'members/roles/:roleId',
        name: 'OrganizationRoleEdit',
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationRoleEditPage'),
        props: true,
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' } },
            { text: 'Members', to: { name: 'OrganizationMembers' } },
            { text: 'Roles', to: { name: 'OrganizationRoles' } },
            { text: 'Edit Role' }
          ]
        }
      },
      {
        path: 'guide-permissions',
        name: 'OrganizationGuidePermissions',
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationGuidePermissionsPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' } },
            { text: 'Members', to: { name: 'OrganizationMembers' } },
            { text: 'Guide Permissions' }
          ]
        }
      },
      {
        path: 'exercises',
        name: 'OrganizationExercises',
        props: true,
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationExercisesPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' }},
            { text: 'Assignment Library' }
          ]
        }
      },
      {
        path: 'exercises/new',
        name: 'OrganizationExerciseNew',
        props: route => ({
          orgKey: route.params.orgKey,
          action: 'new'
        }),
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationExerciseEditPage'),
        meta: {
          activeMenuItemRouteName: 'OrganizationExercises',
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' }},
            { text: 'Assignment Library', to: { name: 'OrganizationExercises' } },
            { text: 'New Assignment' }
          ]
        }
      },
      {
        path: 'exercises/:orgExerciseId',
        name: 'OrganizationExercise',
        props: true,
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationExercisePage'),
        meta: {
          activeMenuItemRouteName: 'OrganizationExercises',
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' }},
            { text: 'Assignment Library', to: { name: 'OrganizationExercises' } },
            { text: 'View Assignment' }
          ]
        }
      },
      {
        path: 'exercises/:orgExerciseId/edit',
        name: 'OrganizationExerciseEdit',
        props: route => ({
          orgKey: route.params.orgKey,
          orgExerciseId: route.params.orgExerciseId,
          action: 'edit'
        }),
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationExerciseEditPage'),
        meta: {
          activeMenuItemRouteName: 'OrganizationExercises',
          breadcrumbs: [
            {text: 'Organization', to: {name: 'OrganizationLanding'}},
            {text: 'Assignment Library', to: {name: 'OrganizationExercises'}},
            {text: 'Edit Assignment'}
          ]
        }
      },
      {
        path: 'exercises/:orgExerciseId/copy',
        name: 'OrganizationExerciseCopy',
        props: route => ({
          orgKey: route.params.orgKey,
          orgExerciseId: route.params.orgExerciseId,
          action: 'copy'
        }),
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationExerciseEditPage'),
        meta: {
          activeMenuItemRouteName: 'OrganizationExercises',
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' }},
            { text: 'Assignment Library', to: { name: 'OrganizationExercises' } },
            { text: 'Copy Assignment' }
          ]
        }
      },
      {
        path: 'exercises/:orgExerciseId/replace',
        name: 'OrganizationExerciseReplace',
        props: route => ({
          orgKey: route.params.orgKey,
          orgExerciseId: route.params.orgExerciseId,
          action: 'replace'
        }),
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationExerciseEditPage'),
        meta: {
          activeMenuItemRouteName: 'OrganizationExercises',
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' }},
            { text: 'Assignment Library', to: { name: 'OrganizationExercises' } },
            { text: 'Replace Assignment' }
          ]
        }
      },
      {
        path: 'admin',
        name: 'OrganizationAdmin',
        component: () => import(/* webpackChunkName: "org" */ '../views/org/OrganizationAdminPage'),
        meta: {
          breadcrumbs: [
            { text: 'Organization', to: { name: 'OrganizationLanding' }},
            { text: 'Admin' }
          ]
        }
      },
    ]
  },
  {
    // https://router.vuejs.org/guide/migration/#Removed-star-or-catch-all-routes
    path : '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      allowAnonymous: true
    }
  }

];
