<template>
  <sdo-button
    ref="button"
    :type="type"
    :variant="variant"
    :disabled="busy || disabled"
    :size="size"
    @click="onClick($event)"
  >
    <span v-if="!busy">
      <slot>
        Submit
      </slot>
    </span>
    <span
      v-else
      role="status"
    >
      {{ busyText }}
    </span>
    <div
      v-if="busy"
      class="spinner-border spinner-border-sm ml-1"
    />
  </sdo-button>
</template>

<script>
import SdoButton from "@/components/Sdo/SdoButton";

export default {
  components: { SdoButton },

  props: {
    busy: {
      type: Boolean,
      default: false
    },
    busyText: {
      type: String,
      default: 'Saving...'
    },
    type: {
      type: String,
      default: 'button'
    },
    variant: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  emits: ['click'],

  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
    focus() {
      this.$refs.button.focus();
    }
  }

};
</script>
