<template>
  <router-link
    v-if="tag === 'router-link'"
    :to="props.to"
    :class="computedClasses"
    :target="props.target"
    @click="clicked"
  >
    <slot />
  </router-link>
  <a
    v-else
    :class="computedLinkClasses"
    v-bind="computedLinkAttrs"
    @click="clicked"
  >
    <slot />
  </a>
</template>

<script setup>
import {
  computed,
  useAttrs,
} from "vue";

const props = defineProps({
  active: {
    type: Boolean,
    default: false
  },
  block: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  icon: {
    type: Boolean,
    default: false
  },
  propagate: {
    type: Boolean,
    default: false
  },
  rel: {
    type: String,
    default: null
  },
  size: {
    type: String,
    default: null
  },
  target: {
    type: String,
    default: null
  },
  to: {
    type: [String, Object],
    default: null
  },
  type: {
    type: String,
    default: 'link'
  },
  variant: {
    type: String,
    default: null
  },
});

const emit = defineEmits(['click']);
const attrs = useAttrs();

const tag = computed(() => {
  if (props.disabled || !props.to) {
    return 'a';
  }
  return 'router-link';
});

const styleTypePrefix = props.type === 'button' ? 'btn' : 'link';
const blockPrefix = props.type === 'button' ? 'btn' : 'd';
const size = props.size ?? (props.type === 'button' ? 'md' : null);

const computedClasses = computed(() => [
  [`${styleTypePrefix}`],
  {
    [`${styleTypePrefix}-${props.variant}`]: props.variant !== null,
    [`${styleTypePrefix}-${size}`]: size !== null,
    [`${blockPrefix}-block`]: !!props.block,
    'icon-link': !!props.icon,
    active: !!props.active,
  }
]);

const fallbackHref = '#';

const computedLinkAttrs = computed(() => ({
  'class': computedClasses.value,
  'href': fallbackHref,
  'target': props.target,
  'rel': props.target === '_blank' && props.rel === undefined ? 'noopener' : props.rel || undefined,
  'tabindex': props.disabled
      ? '-1'
      : typeof attrs.tabindex === 'undefined'
          ? null
          : attrs.tabindex,
  'aria-disabled': props.disabled ? true : null,
}));

const computedLinkClasses = computed(() => ({
  disabled: !!props.disabled,
}));

const clicked = e => {
  if (props.disabled) {
    e.preventDefault();
    e.stopPropagation();
    return;
  }

  emit('click', e);

  if (e.defaultPrevented) {
    e.preventDefault();
    if (!props.propagate) {
      e.stopPropagation();
    }
  }
};
</script>
