<template>
  <div>
    <slot />
    <div
      v-for="comment in comments"
      :key="comment.id"
    >
      <div
        class="mb-2"
        :class="itemClass(comment.id)"
      >
        <div
          ref="comments"
          class="font-weight-bold"
          :class="titleClass(comment.type)"
        >
          <component
            :is="titleIcon(comment.type)"
            class="mr-2"
          />
          <span v-if="useAuthorAsTitle">{{ comment.author }}</span>
          <span v-else>{{ comment.title }}</span>
        </div>
        <markdown-viewer
          v-if="comment.text"
          v-model="comment.text"
          class="last-child-no-mb"
        />

        <audit-info
          v-if="!useAuthorAsTitle"
          :author="comment.author"
          :date-utc="comment.createdDateUtc"
        />
        <small
          v-else
          class="text-muted"
        >
          {{ toLocalDateTime(comment.createdDateUtc) }}
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import AuditInfo from "@/components/AuditInfo";
import MarkdownViewer from "@/components/MarkdownViewer";
import { toLocalDateTime } from "@/utils/filters";

export default {
  name: "CommentViewer",

  components: {
    AuditInfo,
    MarkdownViewer
  },

  props: {
    comments: {
      type: Array,
      default: () => { return []; }
    },
    useAuthorAsTitle: {
      type: Boolean,
      default: false
    },
    itemClassPrefix: {
      type: String,
      default: ''
    }
  },

  methods: {
    toLocalDateTime,

    titleClass(commentType) {
      switch (commentType) {
        case 'Approve':
          return 'text-success';
        case 'RequestChanges':
        case 'Recall':
          return 'text-danger';
        default:
          return 'text-info';
      }
    },

    titleIcon(commentType) {
      switch (commentType) {
        case 'Approve':
          return 'i-bi-check';
        case 'Edit':
          return 'i-bi-pencil';
        case 'RequestChanges':
          return 'i-bi-arrow-left-right';
        case 'Recall':
          return 'i-bi-exclamation-octagon';
        default:
          return 'i-bi-chat-fill';
      }
    },

    itemClass(id) {
      if (this.itemClassPrefix) {
        return this.itemClassPrefix + id;
      }
    }

  }
};
</script>
