<template>
  <div
    :id="id"
    ref="sdoModal"
    :class="['modal', 'fade', modalVisibleClass ]"
    :style="{ 'display' : isVisible ? 'block' : 'none' }"
    tabindex="-1"
    role="dialog"
    @keyup.esc="hide"
  >
    <div
      v-if="isVisible"
      :class="dialogClasses"
      role="document"
    >
      <div class="modal-content">
        <div
          v-if="showHeader"
          class="modal-header"
        >
          <h5 class="modal-title">
            {{ title }}
          </h5>

          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="onCancel($event)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <slot />
        </div>

        <div
          v-if="showFooter"
          class="modal-footer"
        >
          <button
            v-if="showCancel"
            type="button"
            class="btn btn-outline-primary"
            :disabled="cancelDisabled"
            @click="onCancel($event)"
          >
            {{ cancelTitle }}
          </button>
          <button
            type="button"
            :class="['btn', `btn-${props.okVariant}`]"
            style="min-width: 65px"
            :disabled="okDisabled"
            @click="onOk($event)"
          >
            {{ okTitle }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <teleport
    v-if="isVisible"
    to="#teleport-destination-modal-backdrop"
  >
    <div class="modal-backdrop fade show" />
  </teleport>
</template>

<script setup>
import { ref, computed, nextTick } from "vue";

const emit = defineEmits(['ok', 'cancel', 'show', 'shown', 'hide', 'hidden']);

const props = defineProps({
  cancelDisabled: {
    type: Boolean,
    default: false
  },
  cancelTitle: {
    type: String,
    default: 'Cancel'
  },
  centered: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    default: 'sdoModal'
  },
  okDisabled: {
    type: Boolean,
    default: false
  },
  okTitle: {
    type: String,
    default: 'OK'
  },
  okVariant: {
    type: String,
    default: 'primary'
  },
  scrollable: {
    type: Boolean,
    default: false
  },
  showCancel: {
    type: Boolean,
    default: true
  },
  showFooter: {
    type: Boolean,
    default: true
  },
  showHeader: {
    type: Boolean,
    default: true
  },
  size: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
});

const isVisible = ref(false);
const sdoModal = ref(null);

const modalVisibleClass = computed(() => {
  return isVisible.value ? 'show' : '';
});

const dialogClasses = computed(() => [
  {
    'modal-dialog': true,
    [`modal-${props.size}`]: props.size && (props.size === 'sm' || props.size === 'lg' || props.size === 'xl'),
    'modal-dialog-centered': props.centered === true,
    'modal-dialog-scrollable': props.scrollable === true,
  }
]);

// Inspired by https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
let focusableContent = [];
let firstFocusableElement = null;
let lastFocusableElement = null;
let returnFocusTo = null;

const initFocus = () => {
  nextTick(() => {
    focusableContent = sdoModal.value.querySelectorAll(focusableElements);
    firstFocusableElement = focusableContent[0];
    lastFocusableElement = focusableContent[focusableContent.length - 1];

    // Annoyingly, this setTimeout seems necessary give the DOM time to update so that focus can be called on the first element
    setTimeout(() => {
      firstFocusableElement.focus();
    }, 10);
  });

  document.addEventListener('keydown', handleTabKey);
};

const handleTabKey = (e) => {
  let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) {
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus();
      e.preventDefault();
    }
  } else {
    if (document.activeElement === lastFocusableElement) {
      firstFocusableElement.focus();
      e.preventDefault();
    }
  }
};

const onOk = (e) => {
  emit('ok', e);

  if (e.defaultPrevented) {
    return;
  }

  hide();
};

const onCancel = (e) => {
  emit('cancel', e);

  if (e.defaultPrevented) {
    return;
  }

  hide();
};

const show = (elementToReturnFocusTo) => {
  returnFocusTo = elementToReturnFocusTo;

  emit('show');
  isVisible.value = true;
  document.body.classList.add('modal-open');
  nextTick(() => {
    emit('shown');
  });

  initFocus();
};

const hide = () => {
  document.removeEventListener('keydown', handleTabKey);

  emit('hide');
  document.body.classList.remove('modal-open');
  isVisible.value = false;
  nextTick(() => {
    emit('hidden');
  });

  if (returnFocusTo && typeof returnFocusTo.focus === 'function') {
    nextTick(() => {
      returnFocusTo.focus();
      returnFocusTo = null;
    });
  }
};

defineExpose({
  show,
  hide
});
</script>
