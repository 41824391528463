<template>
  <div class="text-center">
    <h3>Sign-in in progress</h3>
    <sdo-loader label="Please Wait..." />
  </div>
</template>

<script>
import SdoLoader from "@/components/Sdo/SdoLoader";

export default {
  components: { SdoLoader },
  async created() {
    try {
      await this.$auth.signInRedirectCallback();
    } catch (e) {
      console.log("Error during signInRedirectCallback " + e);
      await this.$router.push("/unauthorized");
    }
  }
};
</script>
