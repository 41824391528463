import { createI18n } from 'vue-i18n';
import config from "@/config";
import en from "./locales/en.json";

const i18n = createI18n({
  legacy: false,
  locale: config.locale,
  fallbackLocale: config.localeFallback,
  allowComposition: true,
  messages: {
    en
  }
});

export default i18n;
