<template>
  <div class="p-5 d-flex flex-column justify-content-center align-items-center">
    <div class="spinner-grow" />
    <div
      class="small pt-2"
      :class="{'sr-only': labelData.screenReaderOnly}"
      role="status"
    >
      {{ labelData.label }}
    </div>
  </div>
</template>

<script>
/* Variables declared outside of module scope (so they can be used in defaulting props) */
const labelDefault = "Loading...";
</script>

<script setup>
import { computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    default: labelDefault
  }
});

const labelData = computed(() => {
  return {
    screenReaderOnly: props.label.length === 0,
    label: props.label.length === 0 ? labelDefault : props.label
  };
});
</script>
