import AuthService from "@/services/authService";

export default {
  install(app) {

    let axios = app.config.globalProperties.$axios;

    if (!axios) {
      throw new Error('The Axios plugin must be registered before the Auth plugin on the Vue instance');
    }

    app.config.globalProperties.$auth = new AuthService(axios);
  }
};
