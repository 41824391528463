import { createRouter, createWebHistory } from 'vue-router';
import routes from "@/router/routes";
import { useApiStore } from "@/stores/apiStore";
import { useOrganizationStore } from "@/stores/organizationStore";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {

  const apiStore = useApiStore();
  const organizationStore = useOrganizationStore();

  apiStore.resetApiErrorState();

  if (to.meta && to.meta.allowAnonymous) {
    next();
    return;
  }

  router.app.config.globalProperties.$auth.getUser()
    .then(async (user) => {
      let notLoggedIn = !user || user.expired;

      if (notLoggedIn) {
        return router.app.config.globalProperties.$auth.login();
      } else {
        console.info(`router.beforeEach handler is setting default Api Token`, new Date());
        router.app.config.globalProperties.$auth.setDefaultApiToken(user);

        if (to.params.orgKey) {
          await organizationStore.getOrganizationContextAsync(to.params.orgKey);
        }

        next();
      }
    })
    .catch(function (err) {
      console.log(err);
      next({ name: 'AuthenticationError'});
    });
});

export default router;
