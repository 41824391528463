// Use this file to centralize all client side configuration exposed via `process.env.VUE_APP_*` variables.
// Its purpose is mainly to facilitate unit testing. In a "normal" node.js app you could simply set the values for
// process.env.whatever in your tests but these VUE_APP variables are special and are built into the code by webpack.
// https://stackoverflow.com/questions/52871895/cant-set-values-on-process-env-in-client-side-javascript
export default {
  apiUrl: process.env.VUE_APP_API_URL,
  appName: process.env.VUE_APP_NAME,
  envName: process.env.VUE_APP_ENV_NAME,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  localeFallback: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  oidcClientId: process.env.VUE_APP_OIDC_CLIENT_ID,
  oidcTenantId: process.env.VUE_APP_OIDC_TENANT_ID,
  oidcDomain: process.env.VUE_APP_OIDC_DOMAIN,
  fusionAuthEnabled: process.env.VUE_APP_FUSIONAUTH_ENABLED === "true",
  websiteBaseUrl: process.env.VUE_APP_WEBSITE_BASE_URL,
};
