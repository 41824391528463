<template>
  <div class="text-center">
    <h3>You do not have access to this resource.</h3>
    <div v-if="isLoggedIn">
      <p>Trying logging out and logging in with an authorized user.</p>
      <sdo-button
        variant="primary"
        @click="logout"
      >
        Logout
      </sdo-button>
    </div>
    <div v-if="!isLoggedIn">
      <p>Login in with an authorized user.</p>
      <sdo-button
        variant="primary"
        @click="login"
      >
        Login
      </sdo-button>
    </div>
  </div>
</template>

<script>
import SdoButton from "@/components/Sdo/SdoButton";

export default {
  name: "UnauthorizedVue",
  components: { SdoButton },

  data() {
    return {
      isLoggedIn: false
    };
  },

  mounted() {
    this.$auth.getUser().then((user) => {
      this.isLoggedIn = (user !== null && !user.expired);
    });
  },

  methods: {
    login() {
      this.$auth.login();
    },

    logout() {
      this.$auth.logout();
    }
  }
};
</script>
