import { defineStore } from 'pinia';

export const API_ERROR_STATE_NONE = '';
export const API_ERROR_STATE_NOTFOUND = 'notfound';
export const API_ERROR_STATE_UNAUTHORIZED = 'unauthorized';
export const API_ERROR_STATE_NETWORK = 'network';

const allowedApiErrorStates = [
  API_ERROR_STATE_NONE,
  API_ERROR_STATE_NOTFOUND,
  API_ERROR_STATE_UNAUTHORIZED,
  API_ERROR_STATE_NETWORK
];

export const useApiStore = defineStore('apiStore', {
  state: () => {
    return {
      apiErrorState: API_ERROR_STATE_NONE,
      apiVersion: '',
      clientVersion: ''
    };
  },

  actions: {
    resetApiErrorState () {
      this.apiErrorState = API_ERROR_STATE_NONE;
    },

    setApiError (errorState) {
      if (!allowedApiErrorStates.includes(errorState)) {
        throw new Error('Invalid API Error State');
      }
      this.apiErrorState = errorState;
    }
  },
});
