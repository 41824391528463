import ApiService from "@/services/apiService";

export default {
  install(app) {

    let axios = app.config.globalProperties.$axios;

    if (!axios) {
      throw new Error('The Axios plugin must be registered before the API plugin on the Vue instance');
    }

    app.config.globalProperties.$api = new ApiService(axios);
  }
};
