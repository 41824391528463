import IBiArchive from '~icons/bi/archive';
import IBiArchiveFill from '~icons/bi/archive-fill';
import IBiArrowClockwise from '~icons/bi/arrow-clockwise';
import IBiArrowLeftRight from '~icons/bi/arrow-left-right';
import IBiArrowRepeat from '~icons/bi/arrow-repeat';
import IBiCardChecklist from '~icons/bi/card-checklist';
import IBiChatFill from '~icons/bi/chat-fill';
import IBiCheck from '~icons/bi/check';
import IBiCheck2Square from '~icons/bi/check2-square';
import IBiDownload from '~icons/bi/download';
import IBiDashSquare from '~icons/bi/dash-square';
import IBiEnvelope from '~icons/bi/envelope';
import IBiExclamationTriangleFill from '~icons/bi/exclamation-triangle-fill';
import IBiExclamationOctagon from '~icons/bi/exclamation-octagon';
import IBiEye from '~icons/bi/eye';
import IBiFiles from '~icons/bi/files';
import IBiFolderSymlink from '~icons/bi/folder-symlink';
import IBiFunnel from '~icons/bi/funnel';
import IBiGearWide from '~icons/bi/gear-wide';
import IBiInboxFill from '~icons/bi/inbox-fill';
import IBiInfoCircle from '~icons/bi/info-circle';
import IBiJournalBookmark from '~icons/bi/journal-bookmark';
import IBiLockFill from '~icons/bi/lock-fill';
import IBiPencil from '~icons/bi/pencil';
import IBiPeople from '~icons/bi/people';
import IBiPersonCircle from '~icons/bi/person-circle';
import IBiPlus from '~icons/bi/plus';
import IBiPlusSquare from '~icons/bi/plus-square';
import IBiQuestionCircleFill from '~icons/bi/question-circle-fill';
import IBiSearch from '~icons/bi/search';
import IBiShieldLock from '~icons/bi/shield-lock';
import IBiThreeDots from '~icons/bi/three-dots';
import IBiTrash from '~icons/bi/trash';
import IBiUnlock from '~icons/bi/unlock';
import IBiUpload from '~icons/bi/upload';
import IBiX from '~icons/bi/x';

export default {
  install(app) {
    app.component("IBiArchive", IBiArchive);
    app.component("IBiArchiveFill", IBiArchiveFill);
    app.component("IBiArrowClockwise", IBiArrowClockwise);
    app.component("IBiArrowLeftRight", IBiArrowLeftRight);
    app.component("IBiArrowRepeat", IBiArrowRepeat);
    app.component("IBiCardChecklist", IBiCardChecklist);
    app.component("IBiChatFill", IBiChatFill);
    app.component("IBiCheck", IBiCheck);
    app.component("IBiCheck2Square", IBiCheck2Square);
    app.component("IBiDownload", IBiDownload);
    app.component("IBiDashSquare", IBiDashSquare);
    app.component("IBiEnvelope", IBiEnvelope);
    app.component("IBiExclamationTriangleFill", IBiExclamationTriangleFill);
    app.component("IBiExclamationOctagon", IBiExclamationOctagon);
    app.component("IBiEye", IBiEye);
    app.component("IBiFiles", IBiFiles);
    app.component("IBiFolderSymlink", IBiFolderSymlink);
    app.component("IBiFunnel", IBiFunnel);
    app.component("IBiGearWide", IBiGearWide);
    app.component("IBiInboxFill", IBiInboxFill);
    app.component("IBiInfoCircle", IBiInfoCircle);
    app.component("IBiJournalBookmark", IBiJournalBookmark);
    app.component("IBiLockFill", IBiLockFill);
    app.component("IBiPencil", IBiPencil);
    app.component("IBiPeople", IBiPeople);
    app.component("IBiPersonCircle", IBiPersonCircle);
    app.component("IBiPlus", IBiPlus);
    app.component("IBiPlusSquare", IBiPlusSquare);
    app.component("IBiQuestionCircleFill", IBiQuestionCircleFill);
    app.component("IBiSearch", IBiSearch);
    app.component("IBiShieldLock", IBiShieldLock);
    app.component("IBiThreeDots", IBiThreeDots);
    app.component("IBiTrash", IBiTrash);
    app.component("IBiUnlock", IBiUnlock);
    app.component("IBiUpload", IBiUpload);
    app.component("IBiX", IBiX);
  }
};
