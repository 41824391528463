import { defineStore } from 'pinia';

const defaultState = {
  profile: {
    initialized: false,
    homeRoute: { name: 'Home' }
  }
};

export const useUserStore = defineStore('userStore', {
  state: () => {
    return { ...defaultState };
  },

  actions: {
    async getProfileAsync(reset){
      if (reset) {
        this.profile = {...defaultState.profile};
      }

      if (this.profile.initialized) {
        return;
      }

      const response = await this.app.config.globalProperties.$api.getProfileAsync();

      if (response?.data) {
        const profile = response.data;

        if (profile.isSingleTenantMember) {
          const membership = profile.organizationMemberships.slice(0, 1).shift();
          this.profile = { ...profile, initialized: true, homeRoute: { name: 'OrganizationLanding', params: { orgKey: membership.organization.key } } };
        } else {
          this.profile = { ...profile, initialized: true, homeRoute: defaultState.profile.homeRoute };
        }
      } else {
        this.profile = { ...defaultState.profile, initialized: true };
      }
    },

    resetProfile() {
      this.profile = { ...defaultState.profile, initialized: true };
    }
  }
});
