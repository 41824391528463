<template>
  <div
    v-if="!busy"
  >
    <sdo-button
      variant="secondary"
      class="messageButton d-flex align-items-center"
      style="z-index: 999"
      pill
      @click="openSidebar"
    >
      <i-bi-chat-fill />
      <div class="d-none d-sm-block ml-2">
        Messages
      </div>
    </sdo-button>

    <sidebar
      v-model:visible="sidebarOpen"
      position="right"
      @hide="sidebarHidden"
    >
      <template #header>
        <div
          v-if="!loadingMessages"
          class="sideBarHeader d-flex"
        >
          <strong
            v-if="isLearnerContext"
            class="headerTitle"
          >
            Messages
          </strong>
          <strong
            v-else
            class="headerTitle"
          >
            {{ getTitlePrefix() }} <span style="white-space: nowrap">{{ candidateName }}</span>
          </strong>
        </div>
      </template>
      <div class="px-2 py-2 d-flex flex-column h-100">
        <div
          id="messagesContainer"
          class="mb-2 flex-fill overflow-auto messages"
        >
          <comment-viewer
            v-if="messages && messages.length > 0"
            :comments="messages"
            :use-author-as-title="true"
            item-class-prefix="message"
          >
            <div class="text-center">
              <spinner-button
                v-if="(pageSize * messagesPage) < messagesTotalCount"
                class="showOlderMessagesButton btn-sm mb-2"
                variant="outline-secondary"
                :busy="loadingMessages"
                busy-text="Loading..."
                @click="loadOlderMessages"
              >
                Show older messages
              </spinner-button>
            </div>
          </comment-viewer>
        </div>
        <div>
          <message-form
            v-if="orgStore.organizationContext.memberState === 'Activated'"
            id="learnerMessageForm"
            :busy="savingMessage"
            :errors="messageErrors"
            :editor-options="editorOptions"
            button-label="Send"
            busy-button-label="Sending..."
            @submitted="onSubmitMessageAsync"
          />
        </div>
      </div>
    </sidebar>
  </div>
</template>

<script>
import CommentViewer from "@/components/CommentViewer";
import MessageForm from "@/components/CommentForm";
import Sidebar from "primevue/sidebar";
import SpinnerButton from "@/components/SpinnerButton";
import { validation } from "@/mixins/validation";
import { useOrganizationStore } from "@/stores/organizationStore";
import SdoButton from "@/components/Sdo/SdoButton";

export const MESSAGING_CONTEXT_ORG = "org";
export const MESSAGING_CONTEXT_LEARNER = "learner";

const allowedMessagingContexts = [
  MESSAGING_CONTEXT_ORG,
  MESSAGING_CONTEXT_LEARNER
];

export default {
  name: "LearnerMessages",

  components: {
    SdoButton,
    CommentViewer,
    MessageForm,
    Sidebar,
    SpinnerButton
  },

  mixins: [ validation ],

  props: {
    context: {
      type: String,
      required: true,
      validator: value => {
        return allowedMessagingContexts.includes(value);
      }
    },
    busy: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    return {
      orgStore: useOrganizationStore(),
    };
  },

  data() {
    return {
      messages: [],
      message: '',
      savingMessage: false,
      messageErrors: null,
      messagesPage: 1,
      messagesTotalCount: 0,
      pageSize: 20,
      candidateName: '',
      loadingMessages: false,
      editorOptions: { minHeight: '90px', status: false, toolbar: ['bold', 'italic', 'strikethrough', 'ordered-list', 'unordered-list', '|', 'link', 'image', '|', 'preview' ] },
      sidebarOpen: false,
    };
  },

  computed: {
    isLearnerContext() {
      return this.context === MESSAGING_CONTEXT_LEARNER;
    },
    orgUserId() {
      //TODO: Ensure this works for the learners once we add a learner page with the new route path: /orgs/:orgKey/my
      return this.context === MESSAGING_CONTEXT_LEARNER ?
        this.orgStore.organizationContext.orgUserId : this.$route.params.orgUserId;
    }
  },

  methods: {

    openSidebar() {
      this.initializeMessages();
      this.sidebarOpen = true;
    },

    sidebarHidden() {
      const sidebarMasks = document.getElementsByClassName('sidebar-mask');
      if (sidebarMasks.length > 0) {
        sidebarMasks[0].classList.add('sidebar-mask-leave');
      }
    },

    async initializeMessages() {
      this.messages = [];
      this.messagesTotalCount = 0;
      this.messagesPage = 1;
      await this.getMessages(true);
    },

    async getMessages(scrollToBottom) {
      try {
        this.loadingMessages = true;

        let response = await this.$api.getOrgLearnerMessagesAsync(this.orgStore.organizationContext.key, this.orgUserId, this.messagesPage, this.pageSize);
        if (response.status === 200) {
          this.candidateName = response.data.candidateName;
          this.messagesTotalCount = response.data.totalNumberOfItems;
          let bottomOfNewPageMessage = null;
          for (let message of response.data.candidateComments) {
            // The items are coming in with created date descending but we will display in
            // created date ascending
            if (!bottomOfNewPageMessage) {
              bottomOfNewPageMessage = message;
            }
            this.messages.unshift(message.comment);
          }
          if (scrollToBottom) {
            this.scrollMessagesToBottom();
          }
          else {
            this.scrollItemIntoView(bottomOfNewPageMessage);
          }
        }
      }
      finally
      {
        this.loadingMessages = false;
      }
    },

    async loadOlderMessages() {
      this.messagesPage += 1;
      await this.getMessages(false);
    },

    async onSubmitMessageAsync(messageModel) {
      await this.postMessageAsync(messageModel.comment);
    },

    async postMessageAsync(message) {
      try {
        this.savingMessage = true;
        this.messageErrors = null;

        let data = {
          comment: message
        };

        let response = await this.$api.addOrgLearnerMessageAsync(this.orgStore.organizationContext.key, this.orgUserId, data);

        if (!response.data) {
          this.messageErrors = ['An unknown server error has occurred, please try again'];
          return;
        }

        if (response.data.status === 'Failed') {
          const errors = this.getErrorsFromResponse(response);
          this.messageErrors = Object.values(errors);
          return;
        }

        this.messages.push(response.data.result.comment);
        this.scrollMessagesToBottom();
      } catch (e) {
        this.messageErrors = e;
      } finally {
        this.savingMessage = false;
      }
    },

    getTitlePrefix() {
      if (this.context === MESSAGING_CONTEXT_ORG) {
        if (this.messages.length > 0) {
          return `Messages for`;
        } else {
          return `Add a message for`;
        }
      }
      return '';
    },

    scrollMessagesToBottom() {
      // Wait for the next tick to ensure that the elements we need are rendered
      this.$nextTick(function () {
        // Scroll to the bottom of the messages
        let messagesContainer = document.getElementById('messagesContainer');
        if (messagesContainer) {
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
      });
    },

    scrollItemIntoView(item) {
      this.$nextTick(function () {
        let messagesContainer = document.getElementById('messagesContainer');
        if (messagesContainer) {
          let items = messagesContainer.getElementsByClassName('message' + item.commentId);
          if (items && items.length > 0) {
            items[0].scrollIntoView();
          }
        }
      });
    }
  }
};
</script>

<style scoped>

.messageButton {
  position: fixed;
  bottom: 1%;
  right: 10px;
}

.showOlderMessagesButton {
    background-color: white;
}

.sideBarHeader {
  height: 72px;
  width: 92%;
}

.headerTitle {
  max-height: 72px;
  position: relative;
  overflow: hidden;
}

.headerTitle:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
  height: 36px;
  background: linear-gradient(to right, rgba(248, 249, 250, 0), rgb(248, 249, 250) 50%);
}

</style>
