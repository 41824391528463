class ApiService {

  constructor(axios) {
    this.$axios = axios;
  }

  getProfileAsync = async () => {
    return await this.$axios.get(`/profile`);
  }

  getLearnerInspectionEventsAsync = async (includeArchived, orgKey) => {
    let config = {
      params: {
        includeArchived: !!includeArchived
      }
    };

    if (orgKey) {
      Object.assign(config.params, { orgKey });
    }

    return await this.$axios.get(`/learner/inspection-events`, config);
  }

  getLearnerInspectionEventAsync = async (id) => {
    return await this.$axios.get(`/learner/inspection-events/${id}`);
  }

  getLearnerInspectionEventSharingConfigAsync = async (id) => {
    return await this.$axios.get(`/learner/inspection-events/${id}/sharing`);
  }

  updateLearnerInspectionEventSharingConfigAsync = async (id, data) => {
    return await this.$axios.post(`/learner/inspection-events/${id}/sharing`, data);
  }

  getLearnerAssignmentAsync = async (id) => {
    return await this.$axios.get(`/learner/assignments/${id}`);
  }

  getAssignmentNameAsync = async (id) => {
    return await this.$axios.get(`/learner/assignments/${id}/name`);
  }

  getSharedInspectionEventAsync = async (sharingKey) => {
    return await this.$axios.get(`/shared/inspection-events/${sharingKey}`);
  }

  getSharedInspectionEventAssignmentAsync = async (sharingKey, assignmentId) => {
    return await this.$axios.get(`/shared/inspection-events/${sharingKey}/assignment/${assignmentId}`);
  }

  getSharedInspectionEventAttachmentLinkAsync = async (sharingKey, attachmentId) => {
    return await this.$axios.get(`/shared/inspection-events/${sharingKey}/attachment/${attachmentId}`);
  }

  getAttachmentLinkAsync = async (id) => {
    return await this.$axios.get(`/file/link/attachment/${id}`);
  }

  addEvidenceAsync = async (assignmentId, data) => {
    return await this.$axios.post(`/learner/assignments/${assignmentId}/evidence`, data);
  }

  uploadEvidenceFilesAsync = async (evidenceId, formData) => {
    return await this.$axios.post(`/file/evidence/${evidenceId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      validateStatus: () => {
        return true; // Allow error responses to flow through so that we can get at the data
      },
    });
  }

  recallEvidenceSubmissionAsync = async (orgKey, assignmentId, evidenceId) => {
    return await this.$axios.post(`/learner/assignments/${assignmentId}/evidence/${evidenceId}/recall?orgKey=${orgKey}`);
  }

  unsubscribeFromNotificationsAsync = async (encryptedQueryStringData) => {
    return await this.$axios.post(`/unsubscribe`, {
      d: encryptedQueryStringData
    });
  }

  getInvitationAsync = async (invitationCode) => {
    return await this.$axios.get(`/invitation/${invitationCode}`);
  }

  acceptInvitationAsync = async (invitationCode, invitedEmailAddress, currentUser) => {
    let data = {
      invitedEmail: invitedEmailAddress,
      acceptedByEmail: currentUser.profile.email,
      acceptedByName: currentUser.profile.name ?? `${currentUser.profile.given_name} ${currentUser.profile.family_name}`,
      subjectIdentifier: currentUser.profile.sub
    };

    return await this.$axios.put(`/invitation/${invitationCode}/accept`, data);
  }

  getOrgFocusAreaAsync = async (orgKey, id) => {
    return await this.$axios.get(`/orgs/${orgKey}/focus-areas/${id}`);
  }

  completeAssignmentAsync = async  (orgKey, assignmentId) => {
    return await this.$axios.post(`/learner/assignments/${assignmentId}/complete?orgKey=${orgKey}`);
  }

  revertCompletedAssignmentAsync = async  (orgKey, assignmentId) => {
    return await this.$axios.post(`/learner/assignments/${assignmentId}/revert?orgKey=${orgKey}`);
  }

  getSystemRolesAsync = async () => {
    return await this.$axios.get(`/admin/users/roles`);
  }

  adminGetUsersAsync = async () => {
    return await this.$axios.get(`/admin/users`);
  }

  adminGetUserAsync = async (id) => {
    return await this.$axios.get(`/admin/users/${id}`);
  }

  adminAddUserAsync = async (data) => {
    return await this.$axios.post(`/admin/users`, data);
  }

  adminUpdateUserAsync = async (userId, data) => {
    return await this.$axios.post(`/admin/users/${userId}`, data);
  }

  getOrgCompetenciesAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/competencies`);
  }

  adminGetOrganizationsAsync = async () => {
    return await this.$axios.get(`/admin/organizations`);
  }

  adminGetOrganizationInfoAsync = async (id) => {
    return await this.$axios.get(`admin/organizations/${id}`);
  }

  adminUpdateOrganizationAsync = async (orgId, data) => {
    return await this.$axios.post(`/admin/organizations/${orgId}`, data);
  }

  adminDeleteOrganizationAsync = async (orgId) => {
    return await this.$axios.post(`/admin/organizations/${orgId}/delete`);
  }

  adminAddOrganizationAsync = async (data) => {
    return await this.$axios.post(`/admin/organizations`, data);
  }

  getMasterLibraryExercisesAsync = async (queryParams) => {
    let config = {
      params: queryParams
    };

    return await this.$axios.get(`/admin/master-library`, config);
  }

  getMasterLibraryExerciseAsync = async (id) => {
    return await this.$axios.get(`/admin/master-library/${id}`);
  }

  addMasterLibraryExerciseAsync = async (data) => {
    return await this.$axios.post(`/admin/master-library`, data);
  }

  updateMasterLibraryExerciseAsync = async (exerciseId, data) => {
    return await this.$axios.post(`/admin/master-library/${exerciseId}`, data);
  }

  deleteMasterLibraryExerciseAsync = async (exerciseId) => {
    return await this.$axios.delete(`/admin/master-library/${exerciseId}`);
  }

  archiveMasterLibraryExerciseAsync = async (exerciseId, data) => {
    return await this.$axios.post(`/admin/master-library/${exerciseId}/archive`, data);
  }

  restoreMasterLibraryExerciseAsync = async (exerciseId, data) => {
    return await this.$axios.post(`/admin/master-library/${exerciseId}/restore`, data);
  }

  addMasterLibraryExerciseCommentAsync = async (exerciseId, data) => {
    return await this.$axios.post(`/admin/master-library/${exerciseId}/comment`, data);
  }

  getMasterLibraryExercisePersonasAllowedForAssignmentCompletionAsync = async () => {
    return await this.$axios.get(`/admin/master-library/personas-allowed-for-assignment-completion`);
  }

  getMasterLibraryExerciseInternalTagNamesAsync = async () => {
    return await this.$axios.get(`/admin/master-library/internal-tags`);
  }

  exportBillingReport = async () => {
    return await this.$axios.get(`/admin/organizations/billing-report`);
  }

  getMasterLibraryExercisePublicTagNamesAsync = async (includeDraft) => {
    let config = null;

    if (includeDraft === true) {
      config = {
        params: {
          includeDraft: includeDraft
        }
      };
    }

    return await this.$axios.get(`/admin/master-library/public-tags`, config);
  }

  getOrganizationContextAsync = async (key) => {
    return await this.$axios.get(`/orgs/${key}`);
  }

  getOrgMembersAsync = async (key, filters) => {
    let params = {};

    if (filters) {
      params.tags = filters.tags.join(',');
      params.memberStates = filters.memberStates.join(',');
    }

    return await this.$axios.get(`/orgs/${key}/members`, { params: params });
  }

  getOrgMemberAsync = async (key, id) => {
    return await this.$axios.get(`/orgs/${key}/members/${id}`);
  }

  getOrgExercisesAsync = async (key, includeArchived) => {
    let config = null;

    if (includeArchived === true) {
      config = {
        params: {
          includeArchived: includeArchived
        }
      };
    }

    return await this.$axios.get(`/orgs/${key}/exercises`, config);
  }

  getOrgExerciseTagsNamesAsync = async (key) => {
    return await this.$axios.get(`/orgs/${key}/exercises/tags`);
  }

  getOrgExerciseAsync = async (key, orgExerciseId) => {
    return await this.$axios.get(`/orgs/${key}/exercises/${orgExerciseId}`);
  }

  getOrgRolesAsync = async (key) => {
    return await this.$axios.get(`/orgs/${key}/roles`);
  }

  getOrgPersonasAllowedForAssignmentCompletion = async (key) => {
    return await this.$axios.get(`/orgs/${key}/exercises/personas-allowed-for-assignment-completion`);
  }

  orgInviteMemberAsync = async (key, data) => {
    return await this.$axios.post(`/orgs/${key}/invite`, data);
  }

  orgReinviteMemberAsync = async (key, userId, data) => {
    return await this.$axios.post(`/orgs/${key}/members/reInvite/${userId}`, data);
  }

  orgAddMemberAsync = async (key, data) => {
    return await this.$axios.post(`/orgs/${key}/members`, data);
  }

  orgUpdateMemberAsync = async (key, orgUserId, data) => {
    return await this.$axios.post(`/orgs/${key}/members/${orgUserId}`, data);
  }

  orgDeleteMemberAsync = async (key, orgUserId) => {
    return await this.$axios.delete(`/orgs/${key}/members/${orgUserId}`);
  }

  getOrgLearnersAsync = async (key, requestParams) => {
    return await this.$axios.get(`/orgs/${key}/learners`, {
      params: requestParams
    });
  }

  getOrgLearnerAsync = async (key, orgUserId) => {
    return await this.$axios.get(`/orgs/${key}/learners/${orgUserId}`);
  }

  getOrgLearnerInspectionEventsAsync = async (key, orgUserId, includeArchived) => {
    let config = {
      params: {
        includeArchived: !!includeArchived
      }
    };
    return await this.$axios.get(`/orgs/${key}/learners/${orgUserId}/inspection-events`, config);
  }

  getOrgLearnerInspectionEventAsync = async (key, orgUserId, inspectionEventId) => {
    return await this.$axios.get(`/orgs/${key}/learners/${orgUserId}/inspection-events/${inspectionEventId}`);
  }

  addOrgLearnerInspectionEventAsync = async (key, orgUserId, data) => {
    return await this.$axios.post(`/orgs/${key}/learners/${orgUserId}/inspection-events`, data);
  }

  updateOrgLearnerInspectionEventAsync = async (key, orgUserId, inspectionEventId, data) => {
    return await this.$axios.post(`/orgs/${key}/learners/${orgUserId}/inspection-events/${inspectionEventId}`, data);
  }

  assignInspectionEventToLearnersAsync = async (key, orgUserId, inspectionEventId, data) => {
    return await this.$axios.post(`/orgs/${key}/learners/${orgUserId}/inspection-events/${inspectionEventId}/assign-to-learners`, data);
  }

  getOrgLearnerTagsAsync = async (key) => {
    return await this.$axios.get(`/orgs/${key}/learners/tags`);
  }

  getOrgMemberTagsAsync = async (key) => {
    return await this.$axios.get(`/orgs/${key}/members/tags`);
  }

  updateOrgExerciseAsync = async (key, orgExerciseId, data) => {
    return await this.$axios.post(`/orgs/${key}/exercises/${orgExerciseId}`, data);
  }

  addOrgExerciseAsync = async (key, data) => {
    return await this.$axios.post(`/orgs/${key}/exercises`, data);
  }

  replaceOrgExerciseAsync = async (key, orgExerciseIdToReplace, data) => {
    return await this.$axios.post(`/orgs/${key}/exercises/${orgExerciseIdToReplace}/replace`, data);
  }

  archiveOrgExerciseAsync = async (key, orgExerciseId, data) => {
    return await this.$axios.post(`/orgs/${key}/exercises/${orgExerciseId}/archive`, data);
  }

  restoreOrgExerciseAsync = async (key, orgExerciseId, data) => {
    return await this.$axios.post(`/orgs/${key}/exercises/${orgExerciseId}/restore`, data);
  }

  addOrgExerciseCommentAsync = async (key, orgExerciseId, data) => {
    return await this.$axios.post(`/orgs/${key}/exercises/${orgExerciseId}/comment`, data);
  }

  deleteOrgExerciseAsync = async (key, orgExerciseId) => {
    return await this.$axios.delete(`/orgs/${key}/exercises/${orgExerciseId}`);
  }

  getOrgAssignmentsByExerciseAsync = async (orgKey, orgExerciseId) => {
    return await this.$axios.get(`/orgs/${orgKey}/exercises/${orgExerciseId}/assignments`);
  }

  getGetAllOrgProgramGuidesAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/members/program-guides`);
  }

  getOrgGuideOptionsAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/members/guide-options`);
  }

  getOrgAssignmentsForReviewAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/assignments/for-review`);
  }

  claimOrgAssignmentAsync = async (orgKey, assignmentId) => {
    return await this.$axios.post(`/orgs/${orgKey}/assignments/${assignmentId}/claim`);
  }

  releaseOrgAssignmentAsync = async (orgKey, assignmentId) => {
    return await this.$axios.post(`/orgs/${orgKey}/assignments/${assignmentId}/release`);
  }

  importOrgLearnersAsync = async (orgKey, formData) => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      validateStatus: () => {
        return true; // Allow error responses to flow through so that we can get at the data
      },
    });
  }

  getOrgGuidePermissionsAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/members/guide-permissions`);
  }

  orgUpdateGuidePermissionsAsync = async (orgKey, userId, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/members/${userId}/guide-permissions`, data);
  }

  getOrgLearnerOptionsAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/members/learner-options`);
  }

  getOrgAssignmentAsync = async (orgKey, orgUserId, assignmentId) => {
    return await this.$axios.get(`/orgs/${orgKey}/learners/${orgUserId}/assignments/${assignmentId}`);
  }

  saveOrgReviewAsync = async (orgKey, orgUserId, assignmentId, evidenceId, data) => {
    return await this.$axios.put(`/orgs/${orgKey}/learners/${orgUserId}/assignments/${assignmentId}/evidence/${evidenceId}/review`, data);
  }

  orgUpdateAssignmentInternalNotesAsync = async (orgKey, orgUserId, assignmentId, data)  => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/${orgUserId}/assignments/${assignmentId}/internal-notes`, data);
  }

  orgAddEvidenceAsync = async (orgKey, orgUserId, assignmentId, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/${orgUserId}/assignments/${assignmentId}/evidence`, data);
  }

  orgRevertCompletedAssignmentAsync = async  (orgKey, assignmentId) => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/assignments/${assignmentId}/revert`);
  }

  orgModerateEvidenceReviewAsync = async (orgKey, orgUserId, reviewId, evidenceId, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/${orgUserId}/evidence/${evidenceId}/review/${reviewId}/moderate`, data);
  }

  orgAddEvidenceReviewCommentAsync = async (orgKey, orgUserId, reviewId, evidenceId, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/${orgUserId}/evidence/${evidenceId}/review/${reviewId}/comment`, data);
  }

  orgRecallEvidenceSubmissionAsync = async (orgKey, orgUserId, evidenceId) => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/${orgUserId}/evidence/${evidenceId}/recall`);
  }

  orgRecallReviewAsync = async (orgKey, orgUserId, reviewId, evidenceId, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/${orgUserId}/evidence/${evidenceId}/review/${reviewId}/recall`, data);
  }

  getOrgLearnerMessagesAsync = async (orgKey, orgUserId, page, pageSize) => {
    return await this.$axios.get(`/orgs/${orgKey}/learners/${orgUserId}/messages?page=${page}&pageSize=${pageSize}`);
  }

  addOrgLearnerMessageAsync = async (orgKey, orgUserId, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/learners/${orgUserId}/messages`, data, {bypassGlobalExceptionHandling: true});
  }

  getOrgRolePermissionsAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/roles/org-permissions`);
  }

  setOrgRolePermissionsAsync = async (orgKey, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/roles/org-permissions`, data);
  }

  createOrgRoleAsync = async (orgKey, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/roles`, data);
  }

  updateOrgRoleAsync = async (orgKey, roleId, data) => {
    return await this.$axios.post(`/orgs/${orgKey}/roles/${roleId}`, data);
  }

  getOrgRoleAsync = async (orgKey, roleId) => {
    return await this.$axios.get(`/orgs/${orgKey}/roles/${roleId}`);
  }

  deleteOrgRoleAsync = async (orgKey, roleId) => {
    return await this.$axios.delete(`/orgs/${orgKey}/roles/${roleId}`);
  }

  getOrgRolePersonasAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/roles/personas`);
  }

  exportAssignmentFlowMetricsAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/metrics/assignment-flow`);
  }

  exportAssignmentStatusReportAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/metrics/assignment-status`);
  }

  getOrgSubmissionStatsAsync = async (orgKey) => {
    return await this.$axios.get(`/orgs/${orgKey}/learners/submission-stats`);
  }

}

// this method is purpose built only to support the simple network exception override handler built in to some calls above
// it may be necessary to fill this out a bit more if we want to reuse it for other scenarios
function responsesAreValid(responses, options) {
  let config = {
    expectOperationResult: false,
    ...options
  };

  if (!Array.isArray(responses)) {
    return false;
  }

  return !responses.some((response) => !response || !response.data || (config.expectOperationResult && response.data.status !== 'Success'));
}

export {
  ApiService as default,
  responsesAreValid
};
