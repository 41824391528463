export const validation = {

  data() {
    return {
      errorMessages: []
    };
  },

  methods: {

    inputState({ $dirty, $invalid }) {
      if (!$dirty) {
        return null;
      }

      return !$invalid;
    },

    getErrorsFromResponse(response) {
      if (!response || !response.data) {
        return [];
      }

      // Expecting response.data to be one of the following:
      // 1. An object with keys as property names and values as arrays or strings with error messages.
      // 2. An object with a property named 'errors' that contains the same as above.
      let errorObj = 'errors' in response.data ? response.data.errors : response.data;
      let errors = [];

      for (let propertyName in errorObj) {
        // eslint-disable-next-line no-prototype-builtins
        if (!errorObj.hasOwnProperty(propertyName)) {
          continue;
        }

        let value = errorObj[propertyName];

        if (Array.isArray(value)) {
          errors = errors.concat(value);
        } else {
          errors.push(value.toString());
        }
      }

      return errors;
    },

    // Expecting `e` to be an API response or an Error object with a response property
    handleErrors(e, toastValidationErrors) {
      this.errorMessages = [];

      if (!e) {
        return;
      }

      let response = null;

      if (e.isAxiosError && !e.response) {
        this.errorMessages.push('A network error has occurred, please try the operation again');
        return;
      }

      if (e.status && e.data) {
        // If the param has a status and data property, it must be a response
        response = e;
      } else if (e.response) {
        // If the param has a response property, use it as the response below
        response = e.response;
      } else {
        // Must be a javascript exception unrelated to an api call
        console.log(e);
        return;
      }

      if ((response.status === 400 && response.data)
        || (response.status === 200 && response.data && response.data.status === 'Failed')
        || response.status === 500
      ) {
        let errors = this.getErrorsFromResponse(response);

        if (errors.length === 0 || response.status === 500) {
          // We've determined that the result is error/failed but the server did not provide any errors messages.
          // In this case, add a generic error to show to the user.
          errors = ['An unknown error occurred.'];
        }

        if (toastValidationErrors) {
          errors.forEach(x => {
            if (!this.toast) {
              console.error('Could not toast this error because the toast composable was not configured in the component setup');
              alert(x);
              return;
            }
            this.toast.error({
              detail: x,
            });
          });
        } else {
          this.errorMessages = errors;
        }
      }
    }
  }
};
