<template>
  <div :class="{'is-invalid': hasError, 'is-valid': !hasError }">
    <div
      v-if="disabled"
      class="border rounded bg-light py-2 px-3 min-ht-40px"
    >
      <markdown-viewer :model-value="modelValue" />
    </div>
    <textarea
      v-else
      v-bind="$attrs"
      :aria-labelledby="labelledBy"
      :name="name"
      :value="innerValue"
      @input="handleInput($event.target.value)"
    />
  </div>
</template>

<script>
import EasyMDE from 'easymde';
import 'easymde/dist/easymde.min.css';
import MarkdownViewer from "@/components/MarkdownViewer";

export default {
  name: 'MarkdownEditor',
  components: {MarkdownViewer},
  inheritAttrs: false,

  props: {
    modelValue: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'markdown-editor'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: Boolean,
    labelledBy: {
      type: String,
      default: ''
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    easyMdeOptions: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  emits: ['update:modelValue'],

  data: () => ({
    innerValue: ''
  }),

  watch: {
    modelValue(val) {
      if (this.isValueUpdateFromInner) {
        this.isValueUpdateFromInner = false;
        return;
      }
      if (!this.disabled) {
        this.easyMde.value(val);
      }
      this.innerValue = val;
    }
  },

  mounted() {
    if (!this.disabled) {
      this.initEditor();
      if (this.autoFocus) {
        this.easyMde.codemirror.focus();
      }
    }
  },

  unmounted() {
    this.easyMde = null;
  },

  methods: {
    handleInput(val) {
      this.isValueUpdateFromInner = true;
      this.$emit('update:modelValue', val);
    },

    initEditor() {
      const easyMdeConfig = Object.assign({
        element: this.$el.firstElementChild,
        initialValue: this.modelValue,
        blockStyles: {
          italic: "_"
        },
        spellChecker: false,
        toolbar: ["bold", "italic", "strikethrough", "ordered-list", "unordered-list", "|", "table", "link", "image", "|", "preview", "side-by-side", "fullscreen" ]
      }, this.easyMdeOptions);

      this.easyMde = new EasyMDE(easyMdeConfig);

      this.easyMde.codemirror.setOption("extraKeys", {
        Tab: false,
        "Shift-Tab": false
      });

      this.easyMde.codemirror.on('change', () => {
        const val = this.easyMde.value();
        this.handleInput(val);
      });
    }
  }

};
</script>
